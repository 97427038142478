import React, { useEffect, useState } from 'react'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'
import { motion, AnimatePresence } from 'framer-motion'
import bgPage from '../../assets/images/bg.jpg'
import { auth } from '../../firebase'
import fireDb from '../../firebase'
import Swal from 'sweetalert2'
import { Helmet } from 'react-helmet'

const translations = {
	en: {
		redeemPage: 'Redeem Page',
		descRedeemPage:
			'This page is used to activate access by entering the order number',
		inputOrderNumber: 'Input Order Number:',
		yourOrderNumber: 'Your Order Number',
		hasRedeemed: 'Has Redeemed',
		checking: 'Checking...',
		send: 'Send',
	},
	id: {
		redeemPage: 'Penukaran',
		descRedeemPage:
			'Halaman ini digunakan untuk mengaktifkan akses dengan memasukkan nomor pesanan',
		inputOrderNumber: 'Masukkan Nomor Pesanan:',
		yourOrderNumber: 'Nomor Pesanan Anda',
		hasRedeemed: 'Sudah Diredeem',
		checking: 'Memeriksa...',
		send: 'Kirim',
	},
}

const RedeemCard = ({
	title,
	content,
	isExpanded,
	onToggle,
	isMandatory,
	mandatoryText,
}) => (
	<motion.div
		initial={{ opacity: 0, y: 20 }}
		animate={{ opacity: 1, y: 0 }}
		transition={{ duration: 0.5 }}
		className="w-full bg-white rounded-xl border-gray-300 border overflow-hidden"
	>
		<div
			className={`flex items-center justify-between p-4 cursor-pointer transition ${isExpanded ? 'bg-[#0081FB] text-white' : 'hover:bg-[#f0f8ff]'}`}
			onClick={onToggle}
		>
			<div className="flex items-center gap-3">
				<h3
					className={`text-lg md:text-xl font-bold ${isExpanded ? 'text-white' : 'text-[#0081FB]'}`}
				>
					{title}
				</h3>
				{isMandatory && (
					<h3
						className={`text-base md:text-lg font-bold px-4 py-1.5 rounded-full ${isExpanded ? 'bg-white text-[#0081FB]' : 'bg-[#0081FB] text-white'}`}
					>
						{mandatoryText}
					</h3>
				)}
			</div>
			<div className={`${isExpanded ? 'text-white' : 'text-[#0081FB]'}`}>
				{isExpanded ? <FaChevronUp /> : <FaChevronDown />}
			</div>
		</div>
		<AnimatePresence initial={false}>
			{isExpanded && (
				<motion.div
					initial={{ height: 0, opacity: 0 }}
					animate={{ height: 'auto', opacity: 1 }}
					exit={{ height: 0, opacity: 0 }}
					transition={{ duration: 0.5, ease: [0.04, 0.62, 0.23, 0.98] }}
					className="overflow-hidden"
				>
					<div className="flex flex-col lg:flex-row p-4 border-t border-gray-200">
						<div className="text-gray-700 flex-1">{content}</div>
					</div>
				</motion.div>
			)}
		</AnimatePresence>
	</motion.div>
)

const GamesVRMetaQuest = ({ user, language }) => {
	const [orderNumber, setOrderNumber] = useState('')
	const [result, setResult] = useState('')
	const [isRedeemed, setIsRedeemed] = useState(false)
	const [checking, setChecking] = useState(false)

	useEffect(() => {
		// Hentikan jika input kosong
		if (!orderNumber) {
			setIsRedeemed(false)
			return
		}

		// Tambahkan delay 1 detik sebelum memeriksa
		const timeoutId = setTimeout(() => {
			const checkOrderStatus = async () => {
				setChecking(true)
				try {
					const snapshot = await fireDb
						.ref(`shopeeOrders/${orderNumber}`)
						.once('value')

					if (snapshot.exists()) {
						const orderData = snapshot.val()
						if (orderData.isRedeemed) {
							setIsRedeemed(true)
						} else {
							setIsRedeemed(false)
						}
					} else {
						setIsRedeemed(false)
					}
				} catch (error) {
					console.error('Error checking order status:', error)
					setIsRedeemed(false)
				}
				setChecking(false)
			}

			checkOrderStatus()
		}, 1000)

		// Bersihkan timeout jika pengguna mengetik lagi
		return () => clearTimeout(timeoutId)
	}, [orderNumber])

	const handleInputChange = (e) => {
		setOrderNumber(e.target.value)
	}

	const handleSubmit = async () => {
		if (!orderNumber) {
			Swal.fire({
				icon: 'error',
				title: 'Error',
				text: 'Nomor pesanan harus diisi!',
			})
			return
		}

		try {
			const snapshot = await fireDb
				.ref(`shopeeOrders/${orderNumber}`)
				.once('value')

			if (!snapshot.exists()) {
				Swal.fire({
					icon: 'error',
					title: 'Error',
					text: 'Nomor pesanan tidak ditemukan!',
				})
				return
			}

			const orderData = snapshot.val()

			if (orderData.isRedeemed) {
				Swal.fire({
					icon: 'warning',
					title: 'Pesanan Sudah Diredeem',
					text: 'Pesanan ini sudah pernah diredeem sebelumnya!',
				})
				return
			}

			if (!Array.isArray(orderData.orderName)) {
				throw new Error('orderName harus berupa array.')
			}

			const categories = orderData.orderName.map((name) => name.toLowerCase())
			const userEmail = user.email

			categories.forEach((category) => {
				const userId = userEmail.split('@')[0].replace(/\./g, '')
				const userData = {
					email: userEmail,
					timeAdded: new Date().toISOString(),
				}
				fireDb.ref(`eligibleUsers/${category}/${userId}`).set(userData)
			})

			await fireDb
				.ref(`shopeeOrders/${orderNumber}`)
				.update({ isRedeemed: true, email: userEmail })

			Swal.fire({
				icon: 'success',
				title: 'Berhasil',
				text: 'Email berhasil ditambahkan ke kategori terkait!',
			})
			setIsRedeemed(true)
			setResult(
				<div className="flex flex-wrap gap-2 mt-4">
					{categories.map((category, index) => (
						<span
							key={index}
							className="bg-[#0081FB] px-4 py-2 rounded-full text-white text-sm font-medium"
						>
							{category.charAt(0).toUpperCase() + category.slice(1)}
						</span>
					))}
				</div>
			)
		} catch (error) {
			console.error('Error processing redemption:', error)
			Swal.fire({
				icon: 'error',
				title: 'Error',
				text: 'Terjadi kesalahan saat memproses pesanan. Silakan coba lagi.',
			})
		}
	}

	return (
		<div>
			<label
				htmlFor="orderNumberVR"
				className="block text-sm font-medium text-gray-700 mb-2"
			>
				{translations[language].inputOrderNumber}
			</label>
			<div className="flex flex-col lg:flex-row items-start gap-4">
				<input
					type="text"
					id="orderNumberVR"
					value={orderNumber}
					onChange={handleInputChange}
					placeholder={translations[language].yourOrderNumber}
					className="w-full border border-gray-300 rounded-lg p-3 focus:outline-none focus:ring-2 focus:ring-[#0081FB]"
				/>
				<button
					onClick={handleSubmit}
					className={`${
						isRedeemed
							? 'bg-gray-400'
							: 'bg-[#0081FB] hover:bg-[#0059b2] transition'
					} text-white px-6 py-3 rounded-lg text-nowrap`}
					disabled={isRedeemed || checking}
				>
					{isRedeemed
						? translations[language].hasRedeemed
						: checking
							? translations[language].checking
							: translations[language].send}
				</button>
			</div>
			{result && <p className="mt-4 text-gray-800">{result}</p>}
		</div>
	)
}

const SteamAccount = ({ language }) => {
	const [orderNumber, setOrderNumber] = useState('')
	const [result, setResult] = useState('')

	const handleSubmit = () => {
		setResult(`Nomor Pesanan: ${orderNumber}`)
	}

	return (
		<div>
			{/* <label
				htmlFor="orderNumberSteam"
				className="block text-sm font-medium text-gray-700 mb-2"
			>
				Masukkan Nomor Pesanan:
			</label>
			<input
				type="text"
				id="orderNumberSteam"
				value={orderNumber}
				onChange={(e) => setOrderNumber(e.target.value)}
				placeholder="Nomor pesanan Anda"
				className="w-full border border-gray-300 rounded-lg p-3 focus:outline-none focus:ring-2 focus:ring-[#0081FB]"
			/>
			<button
				onClick={handleSubmit}
				className="mt-4 bg-[#0081FB] text-white px-6 py-2 rounded-lg hover:bg-[#0059b2] transition"
			>
				Kirim
			</button>
			{result && <p className="mt-4 text-gray-800">{result}</p>} */}
			{language === 'id' ? 'Akan segera hadir!' : 'Coming Soon!'}
		</div>
	)
}

const RedeemPage = () => {
	const language = localStorage.getItem('language') || 'id'
	const [user, setUser] = useState(null)

	useEffect(() => {
		const unsubscribe = auth.onAuthStateChanged(async (user) => {
			if (user) {
				setUser(user)
			} else {
				setUser(null)
			}
		})

		return () => {
			unsubscribe()
		}
	}, [])

	const redeemData = [
		{
			title: 'Games VR Meta Quest',
			content: <GamesVRMetaQuest user={user} language={language} />,
		},
		{
			title: language === 'id' ? 'Akun Steam' : 'Steam Account',
			content: <SteamAccount language={language} />,
		},
	]

	const [expandedIndex, setExpandedIndex] = useState(null)

	const toggleExpand = (index) => {
		if (expandedIndex === index) {
			setExpandedIndex(null)
			window.history.replaceState(null, '', window.location.pathname)
		} else {
			setExpandedIndex(index)
			if (index === 0) {
				window.location.hash = 'games-vr'
			} else if (index === 1) {
				window.location.hash = 'steam-account'
			}
		}
	}

	const handleHashChange = () => {
		const hash = window.location.hash
		if (hash === '#games-vr') {
			setExpandedIndex(0)
		} else if (hash === '#steam-account') {
			setExpandedIndex(1)
		}
	}

	useEffect(() => {
		handleHashChange()
		window.addEventListener('hashchange', handleHashChange)

		return () => {
			window.removeEventListener('hashchange', handleHashChange)
		}
	}, [])

	const [isMobile, setIsMobile] = useState(window.innerWidth < 1200)

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth < 1200)
		}

		window.addEventListener('resize', handleResize)

		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])

	return (
		<div className={`w-full min-h-screen pb-10`}>
			<header
				className="bg-gradient-to-r from-[#0881FB] to-[#0059b2] py-12 text-center text-white px-6 sm:px-10 pt-[120px]"
				style={{
					background: `linear-gradient(to left, rgba(8, 129, 251, 0.95) 10%, rgba(0, 89, 178, 0.95) 100%), url(${bgPage}) center/cover`,
				}}
			>
				<h1 className="text-3xl md:text-4xl font-bold tracking-wide">
					{translations[language].redeemPage}
				</h1>
				<p className="text-base md:text-lg mt-4">
					{translations[language].descRedeemPage}
				</p>
			</header>
			<div className="container mx-auto max-w-full px-5 pt-5">
				<div className="space-y-6">
					{redeemData.map((tutorial, index) => (
						<RedeemCard
							key={index}
							title={tutorial.title}
							videoId={tutorial.videoId}
							content={tutorial.content}
							isExpanded={expandedIndex === index}
							onToggle={() => toggleExpand(index)}
							isMandatory={tutorial.isMandatory}
							mandatoryText={tutorial.mandatoryText}
						/>
					))}
				</div>
			</div>

			<Helmet>
				<title>Redeem | HyperTopia</title>
			</Helmet>
		</div>
	)
}

export default RedeemPage
