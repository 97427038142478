import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { ref, getDownloadURL } from 'firebase/storage'
import { storage } from '../../firebase'
import { FaShoppingCart } from 'react-icons/fa'

const ProductsCard_Home = ({ product, language }) => {
	const {
		productName,
		productStock,
		productPrice,
		productLinkShopee,
	} = product
	const [productPhotoUrl, setProductPhotoUrl] = useState(null)

	useEffect(() => {
		const imageRef = ref(storage, `productPhotos/${productName}`)
		getDownloadURL(imageRef)
			.then((url) => {
				setProductPhotoUrl(url)
			})
			.catch((error) => {
				console.error('Error getting product photo URL:', error)
			})
	}, [productName])

	const formatPriceWithDot = (price) => {
		return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
	}

	return (
		<div className="bg-white rounded-2xl shadow-lg overflow-hidden transition-transform transform hover:scale-105 duration-300 flex flex-col w-full max-w-[300px] mx-auto">
			{/* Image Section */}
			<div className="relative w-full h-56 overflow-hidden bg-gray-100 flex items-center justify-center">
				<img
					src={productPhotoUrl}
					alt={productName}
					className="object-cover h-full w-full"
				/>
			</div>

			{/* Content Section */}
			<div className="p-4 flex flex-col gap-2">
				<h2 className="text-lg font-semibold text-gray-800 truncate">
					{productName}
				</h2>
				<p className="text-sm text-gray-500">
					{language === 'id' ? 'Stok: ' : 'Stock: '}
					{productStock}
				</p>

				{/* Price */}
				<div className="text-[#0081FB] text-xl font-bold mt-2">
					{language === 'id' ? 'Rp' : 'IDR '}
					{formatPriceWithDot(productPrice)}
				</div>

				{/* Action Buttons */}
				<div className="flex gap-2 mt-4">
					{productLinkShopee && (
						<Link to={productLinkShopee} target="_blank" className="w-full">
							<button className="w-full bg-[#0081FB] text-white font-semibold py-2 rounded-lg transition-colors hover:bg-[#006bbd] flex items-center justify-center gap-2">
								<FaShoppingCart /> Shopee
							</button>
						</Link>
					)}
					{/* {productLinkTokopedia && (
						<Link to={productLinkTokopedia} target="_blank" className="w-full">
							<button className="w-full bg-[#0081FB] text-white font-semibold py-2 rounded-lg transition-colors hover:bg-[#006bbd] flex items-center justify-center gap-2">
								<FaShoppingCart /> Tokopedia
							</button>
						</Link>
					)} */}
				</div>
			</div>
		</div>
	)
}

export default ProductsCard_Home
