import firebase from 'firebase/compat/app'
import { getStorage } from 'firebase/storage'
import { getAuth, GoogleAuthProvider } from 'firebase/auth'
import 'firebase/compat/database'

const firebaseConfig = {
  apiKey: "AIzaSyAlDueS28mi3P460kXiFkElH-mpb-U2qqs",
  authDomain: "hypertopia-id-bc.firebaseapp.com",
  databaseURL: "https://hypertopia-id-bc-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "hypertopia-id-bc",
  storageBucket: "hypertopia-id-bc.appspot.com",
  messagingSenderId: "176112373977",
  appId: "1:176112373977:web:cd162829bcc608de3b2ec8"
};

const fireDb = firebase.initializeApp(firebaseConfig)
const storage = getStorage(fireDb)
const auth = getAuth(fireDb)
const provider = new GoogleAuthProvider()
export { storage, auth, provider }
export default fireDb.database()
