import React from 'react'
import { Link } from 'react-router-dom'
import { useDarkModeContext } from '../DarkModeContext'
import PageNotFound from '../assets/images/page-not-found.png'
import { FaHome } from 'react-icons/fa'

const ErrorPage = () => {
	const { isDarkMode } = useDarkModeContext()
	const language = localStorage.getItem('language') || 'id'

	const translations = {
		en: {
			pageNotFound: 'Page Not Found',
			sorry: 'The page may have been deleted or moved to another address.',
			home: 'Home',
		},
		id: {
			pageNotFound: 'Halaman Tidak Ditemukan',
			sorry:
				'Halaman mungkin telah dihapus atau telah dipindahkan ke alamat lain.',
			home: 'Beranda',
		},
	}

	return (
		<div className="flex flex-col gap-2 items-center justify-center w-full px-4 min-h-screen">
			<img
				src={PageNotFound}
				alt="Page Not Found"
				className="w-auto h-auto max-h-72 rounded-xl"
			/>
			<h1
				className={`text-xl font-bold ${isDarkMode ? 'text-white' : ''} mt-4`}
			>
				{translations[language].pageNotFound}
			</h1>
			<p className={`${isDarkMode ? 'text-zinc-300' : ''} mb-4 text-center`}>
				{translations[language].sorry}
			</p>
			<Link to="/">
				<button className="flex flex-row gap-2 items-center justify-center bg-[#0081FB] hover:bg-blue-700 text-white font-semibold px-3 py-2 rounded-md">
					<FaHome />
					<span>{translations[language].home}</span>
				</button>
			</Link>
		</div>
	)
}

export default ErrorPage
