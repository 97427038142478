import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import fireDb, { auth } from '../../firebase'
import { Link } from 'react-router-dom'
import { BsHeadsetVr } from 'react-icons/bs'
import { MdGroups } from 'react-icons/md'
import { Icon } from '@iconify/react'
import QGOLogo from '../../assets/images/qgo-logo.png'
import bgPage from '../../assets/images/bg.jpg'
import { FaAngleRight } from 'react-icons/fa'
import { FaDownload } from 'react-icons/fa'
import { motion } from 'framer-motion'

const SoftwareCard = ({
	logo,
	title,
	description,
	downloads,
	language,
	isEligible,
}) => {
	return (
		<motion.div
			whileHover={{ scale: 1.0 }}
			whileTap={{ scale: 0.98 }}
			className="border-2 bg-white rounded-3xl p-8 w-full mx-auto relative"
		>
			{/* Badge Akses */}
			{title === 'Quest Games Optimizer' && (
				<div
					className={`flex items-center gap-2 absolute top-3 right-3 px-3 py-2 rounded-xl ${
						isEligible ? 'bg-[#0081FB]' : 'bg-red-500'
					}`}
					style={{ zIndex: 10 }}
				>
					<Icon
						icon={isEligible ? 'line-md:check-all' : 'line-md:cancel'}
						fontSize={20}
						className="text-white"
					/>
					<span className="text-sm font-semibold text-white">
						{isEligible
							? language === 'en'
								? 'You have access'
								: 'Anda memiliki akses'
							: language === 'en'
								? 'You do not have access'
								: 'Anda tidak memiliki akses'}
					</span>
				</div>
			)}

			{/* Konten Utama */}
			<div className="flex flex-col items-center gap-2.5 relative h-full">
				<img
					src={logo}
					alt={`${title} Logo`}
					className="h-20 mt-8" // Tambahkan margin top untuk ruang badge
				/>
				<h3 className="text-xl font-semibold text-gray-800 text-center">
					{title}
				</h3>
				<p className="text-sm text-white bg-[#0081FB] rounded-lg px-4 py-2 font-semibold text-center">
					{description}
				</p>

				{/* Jika Eligible */}
				{isEligible && (
					<div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-6 w-full">
						{downloads.map((download) => (
							<Link
								key={download.link}
								to={download.link}
								target="_blank"
								rel="noopener noreferrer"
								className="flex items-center justify-center p-3 border rounded-lg bg-gray-100 transition-all hover:bg-blue-500 hover:text-white w-full"
							>
								{download.icon}
								<span className="ml-2 text-sm">{download.text}</span>
							</Link>
						))}
					</div>
				)}

				{/* Jika Tidak Eligible */}
				{!isEligible && title === 'Quest Games Optimizer' && (
					<div className="flex flex-col items-center justify-center w-full mt-6 p-4 bg-red-50 border border-red-400 rounded-xl text-center">
						<p className="text-red-500 font-semibold text-sm">
							Silahkan membeli produk terkait untuk mendapatkan akses ke Quest
							Games Optimizer!
						</p>
					</div>
				)}
			</div>
		</motion.div>
	)
}

const ListGames = () => {
	const language = localStorage.getItem('language') || 'id'
	const eligibleUsersRef = fireDb.ref('eligibleUsers/qgo')
	const [eligibleQGO, setEligibleQGO] = useState(false)
	const [qgoDownloads, setQgoDownloads] = useState([])

	const [user, setUser] = useState(null)
	const [standaloneGames, setStandaloneGames] = useState([])
	const [pcvrGames, setPcvrGames] = useState([])
	const [gridColsVRGamesMenu, setGridColsVRGamesMenu] = useState('grid-cols-1')
	const [isMobile, setIsMobile] = useState(window.innerWidth < 1200)

	const [loadingStandalone, setLoadingStandalone] = useState(true)
	const [eligibleStandaloneGameQuest, setEligibleStandaloneGameQuest] =
		useState(false)

	const [loadingPCVR, setLoadingPCVR] = useState(true)
	const [eligiblePCVRGameQuest, setEligiblePCVRGameQuest] = useState(false)

	const translations = {
		en: {
			listGames: 'List Games',
			hintInfo:
				'Click one of the menus below to see the list of available games!',
			standalone: 'Standalone',
			multiplayerStatusList: 'Multiplayer Game Status',
			descStandalone:
				'Games that run directly on Quest without a PC connection.',
			pcvr: 'PCVR',
			descPcvr: 'Games that require a PC connection to run.',
			forOptimizerHelper: 'FOR OPTIMIZING GAME PERFORMANCE ON QUEST',
		},
		id: {
			listGames: 'Daftar Game',
			hintInfo: 'Klik salah satu menu di bawah ini untuk melihat daftar game!',
			standalone: 'Standalone',
			multiplayerStatusList: 'Status Multiplayer Game',
			descStandalone: 'Game yang berjalan langsung di Quest tanpa koneksi PC.',
			pcvr: 'PCVR',
			descPcvr: 'Game yang memerlukan koneksi PC untuk dijalankan.',
			forOptimizerHelper: 'UNTUK MENGOPTIMALKAN PERFORMA GAME DI QUEST',
		},
	}

	useEffect(() => {
		const fetchGames = async (type, setGames) => {
			const ref = fireDb.ref(`vrGames/${type}`)
			ref.on('value', (snapshot) => {
				const data = snapshot.val()
				const games = data
					? Object.values(data).filter((game) => game.gameTitle?.trim())
					: []
				setGames(games)
			})
			return () => ref.off('value')
		}

		fetchGames('standalone', setStandaloneGames)
		fetchGames('pcvr', setPcvrGames)
	}, [])

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth < 1200)
			setGridColsVRGamesMenu(window.innerWidth >= 800 ? 'flex-row' : 'flex-col')
		}
		handleResize()
		window.addEventListener('resize', handleResize)
		return () => window.removeEventListener('resize', handleResize)
	}, [])

	useEffect(() => {
		const checkEligibility = async (type, setEligibility, setLoading) => {
			setLoading(true)
			const ref = fireDb.ref(`eligibleUsers/${type}`)
			try {
				const snapshot = await ref
					.orderByChild('email')
					.equalTo(user?.email)
					.once('value')
				setEligibility(!!snapshot.val())
			} catch (error) {
				console.error(`Error checking ${type} eligibility:`, error)
			} finally {
				setLoading(false)
			}
		}

		if (user) {
			checkEligibility(
				'standalone',
				setEligibleStandaloneGameQuest,
				setLoadingStandalone
			)
			checkEligibility('pcvr', setEligiblePCVRGameQuest, setLoadingPCVR)
			checkEligibility('qgo', setEligiblePCVRGameQuest, setLoadingPCVR)
		}
	}, [user])

	useEffect(() => {
		const unsubscribe = auth.onAuthStateChanged(setUser)
		return () => unsubscribe()
	}, [])

	useEffect(() => {
		const unsubscribe = auth.onAuthStateChanged(async (user) => {
			if (user) {
				try {
					const snapshot = await eligibleUsersRef
						.orderByChild('email')
						.equalTo(user.email)
						.once('value')
					const eligibleUsers = snapshot.val()
					if (eligibleUsers) {
						setEligibleQGO(true)
					} else {
						setEligibleQGO(false)
					}
				} catch (error) {
					console.error('Error fetching eligible users:', error.message)
				}
			}
		})

		return () => {
			unsubscribe()
		}
	}, [])

	useEffect(() => {
		const fetchQgoLink = async () => {
			try {
				const snapshot = await fireDb.ref('qgo/linkDownload').once('value')
				const links = snapshot.val()
				if (links) {
					const formattedLinks = Object.keys(links).map((key) => ({
						link: links[key].url,
						icon: <FaDownload size={20} />,
						text: links[key].description || 'Download',
						version: parseFloat(links[key].description.match(/v([\d.]+)/)[1]),
					}))
					formattedLinks.sort((a, b) => b.version - a.version)
					setQgoDownloads(formattedLinks)
				}
			} catch (error) {
				console.error('Error fetching QGO download links:', error.message)
			}
		}

		fetchQgoLink()
	}, [])

	const renderGameCard = (type, games, isEligible, isLoading) => {
		const title = translations[language][type]
		const desc =
			translations[language][
				`desc${type.charAt(0).toUpperCase() + type.slice(1)}`
			]

		return (
			<Link to={`./${type}`} className="h-96 w-full">
				<button className="relative hover:bg-blue-100 bg-white text-black transition-all rounded-3xl border-2 h-full flex flex-col justify-center items-center p-10 gap-4 w-full">
					<div className="flex flex-col gap-4 items-center justify-center h-full">
						<BsHeadsetVr size={60} />
						<span className="text-center">
							<h2 className="text-3xl font-semibold">{title}</h2>
							<p className="text-base">{desc}</p>
						</span>
						<p className="px-3 py-1 border-2 rounded-lg bg-[#0081FB] text-white text-lg font-semibold">
							{games.length} Games
						</p>
					</div>
					<div
						className={`flex items-center gap-2 absolute top-5 right-5 ${
							isEligible ? 'bg-[#0081FB]' : 'bg-red-500'
						} px-3 py-2 rounded-xl text-white`}
					>
						{isLoading ? (
							<>
								<Icon icon="line-md:loading-loop" fontSize={24} />
								<span>
									{language === 'en'
										? 'Checking access...'
										: 'Memeriksa akses...'}
								</span>
							</>
						) : isEligible ? (
							<>
								<Icon icon="line-md:check-all" fontSize={24} />
								<span>
									{language === 'en'
										? 'You have access'
										: 'Anda memiliki akses'}
								</span>
							</>
						) : (
							<>
								<Icon icon="line-md:cancel" fontSize={24} />
								<span>
									{language === 'en' ? 'No access' : 'Tidak memiliki akses'}
								</span>
							</>
						)}
					</div>
				</button>
			</Link>
		)
	}

	return (
		<div className="w-full min-h-screen">
			<header
				className="bg-gradient-to-r from-[#0881FB] to-[#0059b2] py-12 text-center text-white px-6 sm:px-10 pt-[120px]"
				style={{
					background: `linear-gradient(to left, rgba(8, 129, 251, 0.95) 10%, rgba(0, 89, 178, 0.95) 100%), url(${bgPage}) center/cover`,
				}}
			>
				<h1 className="text-4xl font-bold">
					{translations[language].listGames}
				</h1>
				<p className="text-base md:text-lg mt-4">
					{translations[language].hintInfo}
				</p>
			</header>
			<div className="flex flex-col w-full items-center pt-5 px-5">
				<Link to="/vr-games/multiplayer-status" className="w-full mb-4">
					<button className="mb-4 w-full px-4 py-3 rounded-2xl border-2 bg-blue-500 hover:bg-blue-600 text-white">
						<div className="flex justify-between items-center">
							<div className="flex items-center gap-3">
								<MdGroups size={24} />
								<p className="font-semibold">
									{translations[language].multiplayerStatusList}
								</p>
							</div>
							<FaAngleRight size={20} />
						</div>
					</button>
				</Link>
				<div className={`flex ${gridColsVRGamesMenu} gap-4 pb-5 w-full`}>
					{renderGameCard(
						'standalone',
						standaloneGames,
						eligibleStandaloneGameQuest,
						loadingStandalone
					)}
					{renderGameCard(
						'pcvr',
						pcvrGames,
						eligiblePCVRGameQuest,
						loadingPCVR
					)}
					<SoftwareCard
						logo={QGOLogo}
						title="Quest Games Optimizer"
						description={translations[language].forOptimizerHelper}
						downloads={qgoDownloads}
						isEligible={eligibleQGO}
					/>
				</div>
			</div>
			<Helmet>
				<title>{`${translations[language].listGames} | VR Games - HyperTopia`}</title>
			</Helmet>
		</div>
	)
}

export default ListGames
