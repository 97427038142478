import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import fireDb from '../../firebase'
import { auth } from '../../firebase'
import { FaArrowLeft } from 'react-icons/fa6'
import { BsShieldFillCheck } from 'react-icons/bs'
import { FaSync } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import Swal from 'sweetalert2'
import MidtransIcon from '../../assets/images/Midtrans.png'
import { Icon } from '@iconify/react'
import toast, { Toaster } from 'react-hot-toast'

const translations = {
	en: {
		back: 'Back',
		other_qris: 'QRIS',
		creditCard: 'Credit Card',
		credit_card: 'Credit Card',
		gopay: 'GoPay',
		qris: 'QRIS',
		bca_va: 'BCA Virtual Account',
		permata_va: 'Permata Virtual Account',
		bni_va: 'BNI Virtual Account',
		bri_va: 'BRI Virtual Account',
		mandiri_va: 'Mandiri Virtual Account',
		cimb_va: 'CIMB Virtual Account',
		other_va: 'Other Virtual Account',
		choose: 'Choose Payment Method',

		uploadSuccess: 'Upload Success!',
		changeFile: 'Change File',
		sendFile: 'Send File',
		dropYourFileHere: 'Drop your file here',
		dragYourPaymentProofHere:
			'Drag your payment proof file here, or click to select a file',
		orderCompleted: 'Order Completed',
		status: 'Status',
		downloadQRIS: 'Download QRIS',
		orderPayment: 'Order Payment of',
		order: 'Order',
		orderDetails: 'Order Details',
		paymentDetails: 'Payment Details',
		orderId: 'Order ID: ',
		payOrder: 'Pay Order',
		productName: 'Product Name: ',
		quantity: 'Qty(s): ',
		totalPrice: 'Total Price: ',
		timeCreated: 'Time Order Created: ',
		currency: 'IDR ',
		paymentMethod: 'Payment Method',
		confirmPayment: 'Confirm Payment',

		change: 'Change',
		orderTotal: 'Order Total',
		serviceFee: 'Service Fee',
		serviceFeeInfo:
			'To help improve HyperTopia website services, 0.005% of the total order',
		paymentTotal: 'Payment Total',
		securePayment: '100% Secure Payments Guaranteed',
		poweredBy: 'Powered by',

		QRISInfo:
			"Supports QRIS scan from any E-Wallet app (GoPay, ShopeePay, DANA, Livin' by Mandiri, QRIS BNI, and other mBanking QRIS)",
		ShopeePayInfo: 'Supports ShopeePay payment',
		GoPayInfo: 'Supports GoPay payment',
		DANAInfo: 'Supports DANA payment',
		BankMandiriInfo: 'Supports Bank Mandiri Transfer payment',

		adminFree: 'Free Admin',
		notAvailable: 'Not Available',

		notPaymentYet: 'Not Paid Yet',
		orderPaid: 'Order Paid',
		waitingVerification: 'Waiting Verification',
		waitingConfirmation: 'Waiting Confirmation',
		orderConfirmed: 'Order Confirmed',
		orderSent: 'Order Sent',
		seePaymentProof: 'See Payment Proof',
		uploadPaymentProof: 'Upload Payment Proof',
		paymentVerified: 'Payment Verified',

		verifyingAndValidating: 'Currently Verifying and Validating Payment Proof',
		waitingConfirmationFromSeller:
			'Your payment proof is currently being verified. Please wait for further confirmation from us.',

		pleaseToPay: 'Please pay',
		attentionToDigit: 'Pay attention to the nominal digits!',

		invalidFileType: 'Invalid file type. Please upload an image file.',
	},
	id: {
		back: 'Kembali',
		other_qris: 'QRIS',
		creditCard: 'Kartu Kredit',
		credit_card: 'Kartu Kredit',
		gopay: 'GoPay',
		qris: 'QRIS',
		bca_va: 'Virtual Account BCA',
		permata_va: 'Virtual Account Permata',
		bni_va: 'Virtual Account BNI',
		bri_va: 'Virtual Account BRI',
		mandiri_va: 'Virtual Account Mandiri',
		cimb_va: 'Virtual Account CIMB',
		other_va: 'Virtual Account Lainnya',
		choose: 'Pilih Metode Pembayaran',

		uploadSuccess: 'Upload Berhasil!',
		changeFile: 'Ganti File',
		sendFile: 'Kirim File',
		dropYourFileHere: 'Taruh file Anda di sini',
		dragYourPaymentProofHere:
			'Tarik file bukti pembayaran Anda ke sini, atau klik untuk memilih file',
		orderCompleted: 'Pesanan Selesai',
		status: 'Status',
		downloadQRIS: 'Unduh QRIS',
		order: 'Pesanan',
		orderPayment: 'Pembayaran Pesanan dari',
		orderDetails: 'Detail Pesanan',
		paymentDetails: 'Detail Pembayaran',
		orderId: 'ID Pesanan: ',
		payOrder: 'Bayar Pesanan',
		productName: 'Nama Produk: ',
		quantity: 'Jumlah: ',
		totalPrice: 'Total Harga: ',
		timeCreated: 'Waktu Pesanan Dibuat: ',
		currency: 'Rp',
		paymentMethod: 'Metode Pembayaran',
		confirmPayment: 'Konfirmasi Pembayaran',

		change: 'Ubah',
		orderTotal: 'Total Pesanan',
		serviceFee: 'Biaya Layanan',
		serviceFeeInfo:
			'Untuk membantu meningkatkan layanan website HyperTopia, sebanyak 0.005% dari total pesanan',
		paymentTotal: 'Total Pembayaran',
		securePayment: 'Pembayaran Aman 100% Dijamin',
		poweredBy: 'oleh',

		QRISInfo:
			"Mendukung Scan QRIS dari aplikasi E-Wallet apapun (GoPay, ShopeePay, DANA, Livin' by Mandiri, QRIS BNI, dan QRIS mBanking lainnya)",
		ShopeePayInfo: 'Mendukung pembayaran ShopeePay',
		GoPayInfo: 'Mendukung pembayaran GoPay',
		DANAInfo: 'Mendukung pembayaran DANA',
		BankMandiriInfo: 'Mendukung pembayaran Transfer Bank Mandiri',

		adminFree: 'Gratis Admin',
		notAvailable: 'Tidak Tersedia',

		notPaymentYet: 'Belum Bayar',
		orderPaid: 'Pesanan Dibayar',
		waitingVerification: 'Menunggu Verifikasi',
		waitingConfirmation: 'Menunggu Konfirmasi',
		orderSent: 'Pesanan Dikirim',
		orderConfirmed: 'Pesanan Dikonfirmasi',
		seePaymentProof: 'Lihat Bukti Bayar',
		uploadPaymentProof: 'Unggah Bukti Bayar',
		paymentVerified: 'Pembayaran Terverifikasi',

		verifyingAndValidating: 'Sedang Verifikasi dan Validasi Bukti Bayar',
		waitingConfirmationFromSeller:
			'Bukti bayar Anda sedang dalam proses verifikasi. Mohon tunggu konfirmasi lebih lanjut dari kami.',

		pleaseToPay: 'Mohon untuk membayar',
		attentionToDigit: 'Perhatikan digit nominalnya!',

		invalidFileType: 'Tipe file tidak valid. Silakan unggah file gambar.',
	},
}

const PayOrder = () => {
	const { orderId } = useParams()
	const [orderDetails, setOrderDetails] = useState(null)
	const [user, setUser] = useState(null)
	const language = localStorage.getItem('language') || 'id'
	const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('')
	const [showPaymentPopup, setShowPaymentPopup] = useState(false)
	const [paymentUrl, setPaymentUrl] = useState('')

	const [emailStatuses, setEmailStatuses] = useState([])

	const [dataTransactions, setDataTransactions] = useState([])

	const [isMobile, setIsMobile] = useState(window.innerWidth < 1200)

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth < 1200)
		}

		window.addEventListener('resize', handleResize)

		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])

	useEffect(() => {
		const unsubscribe = auth.onAuthStateChanged((user) => {
			if (user) {
				setUser(user)
			} else {
				setUser(null)
			}
		})

		return () => {
			unsubscribe()
		}
	}, [])

	useEffect(() => {
		const getStatuses = () => {
			if (!orderDetails || !orderDetails.items) {
				return
			} else {
				Promise.all(
					orderDetails.items.map((item) => {
						if (
							item.productName ===
							'PCVR - Games For Oculus Meta Quest 1 2 3 dan Pro'
						) {
							return Promise.all(
								item.emails.map((email) => {
									let emailStatus = 'Tidak Terdaftar'
									const userId = email.split('@')[0]
									const eligibleUsersRef = fireDb.ref(`eligibleUsers/${userId}`)
									return eligibleUsersRef.once('value').then((snapshot) => {
										const user = snapshot.val()
										if (user && user.email === email) {
											emailStatus = 'Terdaftar'
										}
										return emailStatus
									})
								})
							)
						} else {
							return []
						}
					})
				).then((statuses) => {
					setEmailStatuses(statuses.flat())
				})
			}
		}
		getStatuses()
	}, [orderDetails])

	useEffect(() => {
		if (user) {
			const orderRef = fireDb.ref('orders').child(user.uid).child(orderId)
			orderRef.on('value', (snapshot) => {
				setOrderDetails(snapshot.val())
			})

			return () => {
				orderRef.off('value')
			}
		}
	}, [orderId, user])

	useEffect(() => {
		if (user) {
			const orderRef = fireDb.ref('orders').child(user.uid).child(orderId)
			orderRef.on('value', (snapshot) => {
				setOrderDetails(snapshot.val())
			})

			const selectedPaymentMethodRef = fireDb.ref(
				`orders/${user.uid}/${orderId}/paymentMethod`
			)

			selectedPaymentMethodRef.on('value', (snapshot) => {
				const paymentMethod = snapshot.val()
				if (paymentMethod) {
					setSelectedPaymentMethod(paymentMethod)
				} else {
					setSelectedPaymentMethod('other_qris')
					selectedPaymentMethodRef.set('other_qris')
				}
			})

			return () => {
				selectedPaymentMethodRef.off('value')
				orderRef.off('value')
			}
		}
	}, [orderId, user, selectedPaymentMethod])

	if (!orderDetails) {
		return <div>Loading...</div>
	}

	const formatDate = (isoString, language) => {
		const date = new Date(isoString)
		const options = {
			year: 'numeric',
			month: 'long',
			day: 'numeric',
			hour: 'numeric',
			minute: 'numeric',
			second: 'numeric',
			timeZoneName: 'short',
		}
		const locale = language === 'id' ? 'id-ID' : 'en-US'
		return date.toLocaleDateString(locale, options)
	}

	const stringToNumber = (string) => {
		return Number(string.replace(/[^0-9.-]+/g, ''))
	}

	const totalPayment = (totalPrice) => {
		return stringToNumber(totalPrice)
	}

	const handlePayOrder = async () => {
		const redirectUrlRef = fireDb.ref(
			`orders/${user.uid}/${orderId}/redirect_url`
		)

		// Tambahkan Toast dengan dukungan multi-bahasa
		await toast.promise(
			(async () => {
				redirectUrlRef.once('value', async (snapshot) => {
					const existingRedirectUrl = snapshot.val()

					if (existingRedirectUrl) {
						setPaymentUrl(existingRedirectUrl)
						setShowPaymentPopup(true)
					} else {
						try {
							const response = await fetch(
								'https://api.hypertopia.shop/api/create_transactions',
								{
									method: 'POST',
									headers: {
										'Content-Type': 'application/json',
									},
									body: JSON.stringify({
										order_id: orderId,
										gross_amount: totalPayment(orderDetails.totalPrice),
									}),
								}
							)

							const data = await response.json()

							if (response.ok) {
								await redirectUrlRef.set(data.redirect_url)

								setPaymentUrl(data.redirect_url)
								setShowPaymentPopup(true)
							} else {
								console.error('Midtrans API Error:', data.error)
								throw new Error(
									language === 'id'
										? 'Gagal memulai pembayaran.'
										: 'Failed to initiate payment.'
								)
							}
						} catch (error) {
							console.error('Error connecting to API:', error)
							throw new Error(
								language === 'id'
									? 'Terjadi kesalahan. Silakan coba lagi.'
									: 'An error occurred. Please try again.'
							)
						}
					}
				})
			})(),
			{
				loading:
					language === 'id'
						? 'Sedang memproses permintaan pembayaran...'
						: 'Processing payment request...',
				success:
					language === 'id'
						? 'Mengalihkan ke popup pembayaran!'
						: 'Redirecting to payment popup!',
				error:
					language === 'id'
						? 'Gagal memproses pembayaran. Silakan coba lagi.'
						: 'Failed to process payment. Please try again.',
			}
		)
	}

	const PaymentPopup = ({ paymentUrl, onClose, orderId }) => {
		const iframeRef = React.useRef(null)

		React.useEffect(() => {
			// Fungsi untuk memeriksa status transaksi
			const checkTransactionStatus = async () => {
				try {
					const response = await fetch(
						'https://api.hypertopia.shop/api/check_status',
						{
							method: 'POST',
							headers: {
								'Content-Type': 'application/json',
							},
							body: JSON.stringify({
								order_id: orderId,
							}),
						}
					)

					const data = await response.json()
					console.log('Transaction status:', data)

					const orderRef = fireDb.ref(`orders/${user.uid}/${orderId}`)

					// Jika status adalah settlement, tutup popup
					if (data.transaction_status === 'settlement') {
						await orderRef.update({ status: 'Pesanan Diproses' })
						console.log('Payment successful, closing popup.')
						onClose() // Panggil fungsi untuk menutup popup
					}
				} catch (error) {
					console.error('Error checking transaction status:', error)
				}
			}

			// Interval untuk memeriksa status setiap 5 detik
			const interval = setInterval(checkTransactionStatus, 5000)

			// Membersihkan interval saat komponen unmount
			return () => clearInterval(interval)
		}, [orderId, onClose])

		return (
			<div className="fixed inset-0 bg-black bg-opacity-70 flex items-center justify-center z-50 p-4">
				<div className="bg-white rounded-xl shadow-2xl w-full max-w-3xl h-[80vh] flex flex-col">
					{/* Header */}
					<div className="flex justify-between items-center px-6 py-4 bg-blue-600 text-white rounded-t-xl">
						<h2 className="text-lg font-semibold">Payment</h2>
						<button
							className="text-2xl hover:text-gray-300 transition-all"
							onClick={onClose}
						>
							&times;
						</button>
					</div>

					{/* Body */}
					<div className="flex-1 overflow-hidden">
						<iframe
							ref={iframeRef}
							src={paymentUrl}
							title="Payment"
							className="w-full h-full"
							frameBorder="0"
							allow="payment"
						></iframe>
					</div>
				</div>
			</div>
		)
	}

	const handleOrderCompleted = () => {
		const orderRef = fireDb.ref(`orders/${user.uid}/${orderId}`)
		orderRef
			.update({ status: 'Pesanan Selesai' })
			.then(() => {
				Swal.fire({
					icon: 'success',
					title: 'Pesanan Selesai!',
					text: 'Terima kasih atas pesanan Anda. Pesanan Anda telah selesai diproses.',
					confirmButtonText: 'OK',
				}).then(() => {
					window.history.replaceState(
						{},
						'',
						`${window.location.pathname}?payment=${selectedPaymentMethod}&hasPaid=${orderDetails.hasPaid}`
					)
					window.location.reload()
				})
			})
			.catch((error) => {
				console.error('Error updating order status:', error)

				Swal.fire({
					icon: 'error',
					title: 'Oops...',
					text: 'Terjadi kesalahan saat memperbarui status pesanan. Silakan coba lagi.',
					confirmButtonText: 'OK',
				})
			})
	}

	const handleCheckStatus = async () => {
		await toast.promise(
			(async () => {
				try {
					const response = await fetch(
						'https://api.hypertopia.shop/api/check_status',
						{
							method: 'POST',
							headers: {
								'Content-Type': 'application/json',
							},
							body: JSON.stringify({
								order_id: orderId,
							}),
						}
					)

					const data = await response.json()
					setDataTransactions(data)

					const orderRef = fireDb.ref(`orders/${user.uid}/${orderId}`)
					orderRef.update({ expiry_time: data.expiry_time })

					if (data.transaction_status === 'settlement') {
						await orderRef.update({ status: 'Pesanan Diproses' })
					} else if (data.transaction_status === 'expire') {
						await orderRef.update({ status: 'Pembayaran Kadaluarsa' })
					} else if (data.transaction_status === 'cancel') {
						await orderRef.update({ status: 'Pesanan Dibatalkan' })
					} else if (data.transaction_status === 'pending') {
						await orderRef.update({ status: 'Menunggu Pembayaran' })
					} else {
						await orderRef.update({ status: 'Belum Memilih Metode Pembayaran' })
					}

					return data
				} catch (error) {
					console.error('Error checking status:', error)
					throw new Error('Failed to check status. Please try again.')
				}
			})(),
			{
				loading:
					language === 'id' ? 'Memeriksa status...' : 'Checking status...',
				success: language === 'id' ? 'Status diperbarui!' : 'Status updated!',
				error:
					language === 'id'
						? 'Gagal memeriksa status.'
						: 'Failed to check status.',
			}
		)
	}

	return (
		<div
			className={`min-h-screen pt-5 mx-5 pb-5 w-full ${!isMobile && 'pt-[100px]'}`}
		>
			<Toaster />
			<Link to="/account/orders">
				<button className="text-red-500 hover:text-red-700 transition-all flex flex-row gap-2 items-center justify-center mb-4">
					<FaArrowLeft className="text-xl" />
					<p className="text-xl font-semibold">{translations[language].back}</p>
				</button>
			</Link>
			{showPaymentPopup && (
				<PaymentPopup
					paymentUrl={paymentUrl}
					onClose={() => setShowPaymentPopup(false)}
					orderId={orderId}
				/>
			)}

			<span className="flex flex-row gap-2">
				<h1 className="text-2xl font-semibold">
					{translations[language].orderDetails}
				</h1>
				<p className="text-base py-1 px-2 bg-blue-500 rounded-lg text-white">
					{orderId}
				</p>
			</span>
			<hr className="my-4" />
			<div className="flex flex-row gap-4 w-full">
				{/* DETAIL PESANAN */}
				<div className="w-full">
					<>
						{orderDetails && orderDetails.items ? (
							orderDetails.items.map((item, index) => (
								<div
									key={index}
									className="flex flex-row gap-4 p-4 border-2 bg-white rounded-xl mb-4"
								>
									<div className="flex flex-col">
										<p className="text-lg font-bold">{item.productName}</p>
										<p>
											{translations[language].currency}
											{item.productPrice.toLocaleString()} x {item.quantity}
										</p>
										{item.productName ===
											'PCVR - Games For Oculus Meta Quest 1 2 3 dan Pro' && (
											<div>
												{item.emails.map((email, emailIndex) => (
													<p
														key={emailIndex}
														className="flex flex-row items-center text-zinc-400"
													>
														<p>
															{emailIndex + 1}. {email}
														</p>
														<p
															className={`text-sm mt-2 rounded-lg px-2 py-1 ml-2 ${emailStatuses && emailStatuses[index * item.emails.length + emailIndex] === 'Terdaftar' ? 'bg-green-500 text-white' : 'bg-red-500 text-white'}`}
														>
															{emailStatuses &&
																emailStatuses[
																	index * item.emails.length + emailIndex
																]}
														</p>
													</p>
												))}
											</div>
										)}
									</div>
								</div>
							))
						) : (
							<p>Loading...</p>
						)}
						<p className="text-sm mt-4 text-zinc-500">
							{translations[language].timeCreated}{' '}
							{formatDate(orderDetails.timeCreated, language)}
						</p>
					</>
				</div>

				{/* DETAIL PEMBAYARAN */}
				<div className="w-[400px] p-4 rounded-xl border-2 bg-white">
					<div className="flex flex-row justify-between items-center">
						<h2 className="text-xl font-semibold">
							{translations[language].paymentDetails}
						</h2>
						<button
							onClick={handleCheckStatus}
							className="bg-blue-500 hover:bg-blue-700 transition-all text-white px-4 py-2 rounded-xl flex flex-row gap-2 items-center"
						>
							<p>Refresh</p>
							<FaSync />
						</button>
					</div>
					<hr className="my-4" />
					<span className="flex justify-between">
						<p>{translations[language].paymentTotal}</p>
						<p className="font-semibold text-[#0081FB]">
							{translations[language].currency}
							{totalPayment(orderDetails.totalPrice).toLocaleString()}
						</p>
					</span>
					{orderDetails.hasPaid && (
						<button className="border-blue-500 border hover:bg-blue-500 hover:text-white transition-all text-blue-500 px-4 py-2 rounded-xl mt-4 w-full">
							{translations[language].seePaymentProof}
						</button>
					)}
					{orderDetails.status === 'Belum Memilih Metode Pembayaran' && (
						<button
							onClick={handlePayOrder}
							className="bg-[#0081FB] hover:bg-blue-700 transition-all text-white px-4 py-2 rounded-xl mt-4 w-full"
						>
							{language === 'id'
								? 'Pilih Metode Pembayaran'
								: 'Choose Payment Method'}
						</button>
					)}
					{orderDetails.status === 'Menunggu Pembayaran' && (
						<button
							onClick={handlePayOrder}
							className="bg-[#0081FB] hover:bg-blue-700 transition-all text-white px-4 py-2 rounded-xl mt-4 w-full"
						>
							{language === 'id' ? 'Bayar Sekarang' : 'Pay Now'}
						</button>
					)}
					{orderDetails.status !== 'Pesanan Selesai' && (
						<button
							onClick={handleOrderCompleted}
							disabled={orderDetails.status !== 'Pesanan Selesai'}
							className="bg-green-500 disabled:bg-gray-300 disabled:text-gray-500 disabled:cursor-not-allowed hover:bg-green-700 transition-all text-white px-4 py-2 rounded-xl mt-2 w-full"
						>
							{translations[language].orderCompleted}
						</button>
					)}
					<span className="flex flex-col mt-4">
						<p className="flex justify-center text-center text-sm text-zinc-500">
							{translations[language].securePayment}
						</p>
						<span className="text-center text-sm flex flex-row gap-1 justify-center items-center">
							<p className="text-zinc-500">
								{translations[language].poweredBy}
							</p>
							<span className="font-semibold text-[#0081FB] flex flex-row gap-1 items-center">
								<img
									src={MidtransIcon}
									alt="Midtrans"
									className="w-20 h-auto my-2 mx-1"
								/>
								<BsShieldFillCheck />
							</span>
						</span>
					</span>

					<hr className="my-4" />

					{/* KETERANGAN TRANSAKSI */}
					{dataTransactions && (
						<div className="flex flex-col gap-0">
							<p className="text-lg font-semibold">
								{language === 'id'
									? 'Keterangan Transaksi'
									: 'Transaction Info'}
							</p>

							{orderDetails.status === 'Belum Memilih Metode Pembayaran' && (
								<span className="bg-red-500 text-white py-2 px-4 rounded-lg w-full text-center mt-4 flex flex-row gap-2 items-center justify-center">
									<p>
										{language === 'id'
											? 'Belum Memilih Metode Pembayaran!'
											: 'Not Selected Payment Method Yet!'}
									</p>
								</span>
							)}

							{orderDetails.status === 'Menunggu Pembayaran' && (
								<span className="bg-zinc-500 text-white py-2 px-4 rounded-lg w-full text-center mt-4 flex flex-row gap-2 items-center justify-center">
									<p>Menunggu Pembayaran</p>
									<Icon icon="akar-icons:clock" className="text-xl" />
								</span>
							)}

							{orderDetails.status === 'Pesanan Diproses' && (
								<span className="bg-yellow-500 text-white py-2 px-4 rounded-lg w-full text-center mt-4 flex flex-row gap-2 items-center justify-center">
									<p>Pesanan Diproses</p>
									<Icon icon="mdi:timer-sand" className="text-xl" />
								</span>
							)}

							{orderDetails.status === 'Pesanan Dibatalkan' && (
								<span className="bg-red-500 text-white py-2 px-4 rounded-lg w-full text-center mt-4 flex flex-row gap-2 items-center justify-center">
									<p>Pesanan Dibatalkan</p>
									<Icon icon="akar-icons:close" className="text-xl" />
								</span>
							)}

							{orderDetails.status === 'Pembayaran Kadaluarsa' && (
								<span className="bg-red-500 text-white py-2 px-4 rounded-lg w-full text-center mt-4 flex flex-row gap-2 items-center justify-center">
									<p>Pembayaran Kadaluarsa</p>
									<Icon icon="pajamas:expire" className="text-xl" />
								</span>
							)}

							{orderDetails.status === 'Pesanan Selesai' && (
								<span className="bg-green-500 text-white py-2 px-4 rounded-lg w-full text-center mt-4 flex flex-row gap-2 items-center justify-center">
									<p>Pesanan Selesai</p>
									<Icon icon="ion:bag-check" className="text-xl" />
								</span>
							)}
						</div>
					)}
				</div>
			</div>
			<Helmet>
				<title>
					{translations[language].orderPayment} {orderId} - HyperTopia
				</title>
			</Helmet>
		</div>
	)
}

export default PayOrder
