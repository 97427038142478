import React, { useState, useEffect } from 'react'
import { useParams, useNavigate, Link } from 'react-router-dom'
import { useDarkModeContext } from '../../DarkModeContext'
import { ref, uploadBytes } from 'firebase/storage'
import { storage } from '../../firebase'
import fireDb from '../../firebase'
import Swal from 'sweetalert2'
import { FaArrowLeft, FaInfoCircle } from 'react-icons/fa'
import { auth } from '../../firebase'
import { Helmet } from 'react-helmet'
import { FaMeta, FaWhatsapp } from 'react-icons/fa6'
import exampleVersion from '../../assets/images/exampleVersion.png'
import { motion } from 'framer-motion'

const initialState_RequestedVRGames = {
	gameTitle: '',
	version: '',
	gameType: 'standalone',
	metaStoreLink: '',
	requestedBy: '',
	whatsAppNumber: '',
	requestType: 'new',
	status: 'Pending',
	reason: '',
	report: '',
	timeRequested: new Date().toISOString(),
	timeProcessed: null,
}

const RequestedVRGames = () => {
	const { isDarkMode } = useDarkModeContext()
	const storedLanguage = localStorage.getItem('language') || 'id'
	const [language, setLanguage] = useState(storedLanguage)
	const [state_RequestedVRGames, setState_RequestedVRGames] = useState(
		initialState_RequestedVRGames
	)
	const { id } = useParams()
	const navigate = useNavigate()
	const [user, setUser] = useState(null) // State untuk menyimpan informasi pengguna
	const [imageUpload_RequestedVRGames, setImageUpload_RequestedVRGames] =
		useState(null)

	const [radioStatus, setRadioStatus] = useState('Pending')

	const translations = {
		en: {
			mustStart62:
				'Your WhatsApp number must start with "62". (Ex: 08123456789 >> 628123456789)',
			whatsAppNumberInfo:
				'Your WhatsApp number will be used to contact you if there is a problem with the game you requested or the game has been uploaded.',
			metaStoreButtonInfo: 'Shortcut to Meta Quest Store is below the form!',
			versionGameInfo:
				'Please see the desired version in Meta Quest Store. (Ex: 1.0.0)',
			findGamesInMetaQuestStore:
				'Find Games in Meta Quest Store, and Copy the link on Meta Store Link!',
			gameTitle: 'Game Title',
			version: 'Version',
			gameType: 'Game Type',
			metaStoreLink: 'Meta Store Link',
			requestType: 'Request Type',
			requestedBy: 'Requested By',
			status: 'Status',
			reason: 'Reason',
			report: 'Report Message',
			whatsAppNumber: 'WhatsApp Number',

			pending: 'Pending',
			process: 'Process',
			postpone: 'Postpone',
			done: 'Done',
			canceled: 'Canceled',

			timeRequested: 'Time Requested',
			save: 'Save',
			update: 'Update',
			backToVRGames: 'Back to Request VR Games List',
			hideEmail: 'Your email will be hidden from public',

			fillHere: 'Fill Here',

			textSuccessfully: 'Your game has been requested successfully!',
			textUpdatedSuccessfully:
				'Your requested game has been updated successfully!',

			textPleaseFillTitle: 'Please fill the game title!',
			textPleaseFillLink: 'Please fill the meta store link!',
			textPleaseFillwhatsAppNumber: 'Please fill the WhatsApp number!',

			textSomethingWrong: 'Something went wrong! Please try again later.',

			success: 'Success!',

			sameTitle:
				'This game title has been requested by another user. Please choose another game title.',

			startLinkMetaStoreWith:
				'Meta Store Link must start with "https://www.meta.com/experiences/".',
		},
		id: {
			mustStart62:
				'Nomor WhatsApp kamu harus diawali dengan "62". (Misal: 08123456789 >> 628123456789)',
			whatsAppNumberInfo:
				'Nomor WhatsApp kamu akan digunakan untuk menghubungi kamu jika ada masalah dengan game yang kamu request atau game sudah selesai diupload.',
			metaStoreButtonInfo:
				'Shortcut ke Meta Quest Store ada di bawah form ini!',
			versionGameInfo:
				'Silahkan lihat versi yang diinginkan di Meta Quest Store. (Misal: 1.0.0)',
			findGamesInMetaQuestStore:
				'Cari Game di Meta Quest Store, dan Salin linknya ke Link Meta Store!',
			gameTitle: 'Judul Game',
			version: 'Versi',
			gameType: 'Tipe Game',
			metaStoreLink: 'Link Meta Store',
			requestType: 'Tipe Request',
			requestedBy: 'Diminta Oleh',
			status: 'Status',
			reason: 'Alasan',
			report: 'Pesan Laporan',
			whatsAppNumber: 'Nomor WhatsApp',

			pending: 'Menunggu',
			process: 'Proses',
			postpone: 'Tertunda',
			done: 'Selesai',
			canceled: 'Dibatalkan',

			timeRequested: 'Waktu Diminta',
			save: 'Simpan',
			update: 'Perbarui',
			backToVRGames: 'Kembali ke Daftar Request Game VR',
			hideEmail: 'Email kamu akan disembunyikan dari publik',

			fillHere: 'Isi Disini',

			textSuccessfully: 'Game kamu berhasil direquest!',
			textUpdatedSuccessfully: 'Game yang kamu request berhasil diperbarui!',

			textPleaseFillTitle: 'Harap isi judul game!',
			textPleaseFillLink: 'Harap isi link meta store!',
			textPleaseFillwhatsAppNumber: 'Harap isi nomor WhatsApp!',

			textSomethingWrong: 'Terjadi kesalahan! Silakan coba lagi nanti.',

			success: 'Berhasil!',

			sameTitle:
				'Judul game ini sudah direquest oleh pengguna lain. Silakan pilih judul game lain.',

			startLinkMetaStoreWith:
				'Link Meta Store harus diawali dengan "https://www.meta.com/experiences/".',
		},
	}

	useEffect(() => {
		// Cek apakah pengguna sudah login, jika iya, atur state user
		const unsubscribe = auth.onAuthStateChanged((user) => {
			if (user) {
				setUser(user)
				setState_RequestedVRGames({
					...state_RequestedVRGames,
					requestedBy: user.email,
				})
			} else {
				setUser(null)
			}
		})

		return () => {
			unsubscribe() // Membersihkan listener saat komponen dibongkar
		}
	}, [])

	const uploadImage_RequestedVRGames = () => {
		if (imageUpload_RequestedVRGames == null) return
		const imageName_RequestedVRGames = `${state_RequestedVRGames.gameTitle}`
		const imageRef_RequestedVRGames = ref(
			storage,
			`requestedVRGames_Images/${imageName_RequestedVRGames}`
		)
		uploadBytes(imageRef_RequestedVRGames, imageUpload_RequestedVRGames)
	}

	useEffect(() => {
		if (id) {
			fireDb.ref(`requestedVRGames/${id}`).on('value', (snapshot) => {
				const existingData = snapshot.val()
				if (existingData) {
					setState_RequestedVRGames({
						gameTitle: existingData.gameTitle || '',
						version: existingData.version || '',
						gameType: existingData.gameType || '',
						metaStoreLink: existingData.metaStoreLink || '',
						requestType: existingData.requestType || 'new',
						requestedBy: existingData.requestedBy || '',
						status: existingData.status || 'pending',
						reason: existingData.reason || '',
						report: existingData.report || '',
						whatsAppNumber: existingData.whatsAppNumber || '',
						timeRequested:
							existingData.timeRequested || new Date().toISOString(),
						timeProcessed: existingData.timeProcessed || null,
					})
				}
			})
		}

		return () => {
			setState_RequestedVRGames({})
		}
	}, [id])

	const handleInputChange_RequestedVRGames = (event) => {
		const { name, value } = event.target
		setState_RequestedVRGames({ ...state_RequestedVRGames, [name]: value })
	}

	const checkGameTitleExists = async () => {
		const snapshot = await fireDb
			.ref('requestedVRGames')
			.orderByChild('gameTitle')
			.equalTo(state_RequestedVRGames.gameTitle)
			.once('value')
		return snapshot.exists()
	}

	const handleSubmit = async (e) => {
		e.preventDefault()

		const gameTitleExists = await checkGameTitleExists()

		if (gameTitleExists && !id) {
			Swal.fire({
				icon: 'error',
				title: 'Oops...',
				text: translations[language].sameTitle,
				confirmButtonColor: '#3085d6',
				confirmButtonText: 'OK',
			})
			return
		}
		if (!state_RequestedVRGames.gameTitle) {
			Swal.fire({
				icon: 'error',
				title: 'Oops...',
				text: translations[language].textPleaseFillTitle,
			})
			return
		} else {
			const requestVRGamesData = {
				gameTitle: state_RequestedVRGames.gameTitle,
				version: state_RequestedVRGames.version,
				gameType: state_RequestedVRGames.gameType,
				metaStoreLink: state_RequestedVRGames.metaStoreLink,
				requestType: state_RequestedVRGames.requestType,
				requestedBy: state_RequestedVRGames.requestedBy,
				status: state_RequestedVRGames.status,
				reason: state_RequestedVRGames.reason,
				report: state_RequestedVRGames.report,
				whatsAppNumber: state_RequestedVRGames.whatsAppNumber,
				timeRequested: state_RequestedVRGames.timeRequested,
			}

			if (user && user.email === 'hypertopiaid@gmail.com') {
				requestVRGamesData.timeProcessed = new Date().toISOString()
			}

			if (!id) {
				fireDb
					.ref(`requestedVRGames/${state_RequestedVRGames.gameTitle}`)
					.set(requestVRGamesData, (err) => {
						if (err) {
							Swal.fire({
								icon: 'error',
								title: 'Oops...',
								text: translations[language].textSomethingWrong,
							})
						} else {
							Swal.fire({
								icon: 'success',
								title: translations[language].success,
								text: translations[language].textSuccessfully,
							})
						}
					})
			} else {
				fireDb
					.ref(`requestedVRGames/${id}`)
					.update(requestVRGamesData, (err) => {
						if (err) {
							Swal.fire({
								icon: 'error',
								title: 'Oops...',
								text: translations[language].textSomethingWrong,
							})
						} else {
							Swal.fire({
								icon: 'success',
								title: translations[language].success,
								text: translations[language].textUpdatedSuccessfully,
							})
						}
					})
			}
			setTimeout(
				() => navigate('/vr-games/request-games', { replace: true }),
				500
			)
		}
		if (!state_RequestedVRGames.gameTitle) {
			Swal.fire({
				icon: 'error',
				title: 'Oops...',
				text: translations[language].textPleaseFillTitle,
			})
			return
		} else {
			const requestVRGamesData = {
				gameTitle: state_RequestedVRGames.gameTitle,
				version: state_RequestedVRGames.version,
				gameType: state_RequestedVRGames.gameType,
				metaStoreLink: state_RequestedVRGames.metaStoreLink,
				requestType: state_RequestedVRGames.requestType,
				requestedBy: state_RequestedVRGames.requestedBy,
				status: state_RequestedVRGames.status,
				reason: state_RequestedVRGames.reason,
				report: state_RequestedVRGames.report,
				whatsAppNumber: state_RequestedVRGames.whatsAppNumber,
				timeRequested: state_RequestedVRGames.timeRequested,
			}

			if (user && user.email === 'hypertopiaid@gmail.com') {
				requestVRGamesData.timeProcessed = new Date().toISOString()
			}

			if (!id) {
				fireDb
					.ref(`requestedVRGames/${state_RequestedVRGames.gameTitle}`)
					.set(requestVRGamesData, (err) => {
						if (err) {
							Swal.fire({
								icon: 'error',
								title: 'Oops...',
								text: translations[language].textSomethingWrong,
							})
						} else {
							Swal.fire({
								icon: 'success',
								title: translations[language].success,
								text: translations[language].textSuccessfully,
							})
						}
					})
			} else {
				fireDb
					.ref(`requestedVRGames/${id}`)
					.update(requestVRGamesData, (err) => {
						if (err) {
							Swal.fire({
								icon: 'error',
								title: 'Oops...',
								text: translations[language].textSomethingWrong,
							})
						} else {
							Swal.fire({
								icon: 'success',
								title: translations[language].success,
								text: translations[language].textUpdatedSuccessfully,
							})
						}
					})
			}
			setTimeout(
				() => navigate('/vr-games/request-games', { replace: true }),
				500
			)
		}
	}

	const formatDate = (dateString) => {
		try {
			const date = new Date(dateString)

			// Check if date is valid
			if (isNaN(date.getTime())) {
				return 'Invalid Date' // or return ''
			}

			return new Intl.DateTimeFormat(language === 'id' ? 'id-ID' : 'en-US', {
				weekday: 'short',
				year: 'numeric',
				month: 'short',
				day: 'numeric',
				hour: '2-digit',
				minute: '2-digit',
				second: '2-digit',
			}).format(date)
		} catch (error) {
			console.error('Error formatting date:', error)
			return 'Invalid Date' // or return ''
		}
	}

	const handleRadioChange = (event) => {
		if (user && user.email === 'hypertopiaid@gmail.com') {
			setRadioStatus(event.target.value)
			setState_RequestedVRGames({
				...state_RequestedVRGames,
				status: event.target.value,
			})
		}
	}

	const [isMobile, setIsMobile] = useState(window.innerWidth < 1200)

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth < 1200)
		}

		window.addEventListener('resize', handleResize)

		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])

	return (
		<div
			className={`pt-10 pb-20 px-8 w-full ${!isMobile && 'pt-[100px]'} min-h-screen flex justify-center items-center`}
		>
			<motion.div
				initial={{ opacity: 0, y: 30 }}
				animate={{ opacity: 1, y: 0 }}
				transition={{ duration: 0.5, ease: 'easeInOut' }}
				className={`p-6 max-w-2xl w-full flex flex-col gap-8 rounded-2xl border-2 ${isDarkMode ? 'bg-zinc-800 border-zinc-700' : 'bg-white border-gray-300'}`}
			>
				{/* Back to VR Games Button */}
				<Link
					to="/vr-games/request-games"
					className="flex items-center justify-center py-2 rounded-xl bg-red-500 text-white font-semibold hover:bg-red-600 transition-all duration-300"
				>
					<FaArrowLeft className="mr-2" />
					{translations[language].backToVRGames}
				</Link>

				{/* Form Section */}
				<form onSubmit={handleSubmit} className="flex flex-col gap-6">
					{/* Game Title */}
					<div className="flex flex-col gap-2">
						<label
							htmlFor="gameTitle"
							className={`font-semibold ${isDarkMode ? 'text-white' : 'text-black'}`}
						>
							{translations[language].gameTitle}
						</label>
						<input
							type="text"
							id="gameTitle"
							name="gameTitle"
							placeholder={translations[language].fillHere}
							value={state_RequestedVRGames.gameTitle || ''}
							onChange={handleInputChange_RequestedVRGames}
							className="p-3 rounded-lg border-2 border-gray-300 w-full"
						/>
					</div>

					{/* Game Type */}
					<div className="flex flex-col gap-2">
						<label
							htmlFor="gameType"
							className={`font-semibold ${isDarkMode ? 'text-white' : 'text-black'}`}
						>
							{translations[language].gameType}
						</label>
						<select
							id="gameType"
							name="gameType"
							value={state_RequestedVRGames.gameType || ''}
							onChange={handleInputChange_RequestedVRGames}
							className="p-3 rounded-lg border-2 border-gray-300 w-full"
						>
							<option value="standalone">Standalone</option>
							<option value="pcvr">PCVR</option>
						</select>
					</div>

					<div className="flex flex-col gap-2">
						<label
							htmlFor="requestType"
							className={`font-semibold ${isDarkMode ? 'text-white' : 'text-black'}`}
						>
							{translations[language].requestType}
						</label>
						<select
							id="requestType"
							name="requestType"
							value={state_RequestedVRGames.requestType || ''}
							onChange={handleInputChange_RequestedVRGames}
							className="p-3 rounded-lg border-2 border-gray-300 w-full"
						>
							<option value="report">Laporan Masalah</option>
							<option value="new">Permintaan Game Baru</option>
							<option value="update">Permintaan Update</option>
						</select>
					</div>

					{/* Meta Store Link */}
					<div className="flex flex-col gap-2">
						<label
							htmlFor="metaStoreLink"
							className={`font-semibold ${isDarkMode ? 'text-white' : 'text-black'}`}
						>
							{translations[language].metaStoreLink}
						</label>
						<input
							type="url"
							id="metaStoreLink"
							name="metaStoreLink"
							placeholder="Ex: https://www.meta.com/experiences/2603836099654226/"
							value={state_RequestedVRGames.metaStoreLink || ''}
							onChange={handleInputChange_RequestedVRGames}
							className="p-3 rounded-lg border-2 border-gray-300 w-full"
						/>
						<span className="text-[#0081FB] font-semibold text-sm flex items-center">
							<FaInfoCircle className="mr-2" />
							{translations[language].metaStoreButtonInfo}
						</span>
					</div>

					{/* Version */}
					<div className="flex flex-col gap-2">
						<label
							htmlFor="version"
							className={`font-semibold ${isDarkMode ? 'text-white' : 'text-black'}`}
						>
							{translations[language].version}
						</label>
						<input
							type="text"
							id="version"
							name="version"
							placeholder={translations[language].fillHere}
							value={state_RequestedVRGames.version || ''}
							onChange={handleInputChange_RequestedVRGames}
							className="p-3 rounded-lg border-2 border-gray-300 w-full"
						/>
						<span className="text-[#0081FB] font-semibold text-sm flex items-center">
							<FaInfoCircle className="mr-2" />
							{translations[language].versionGameInfo}
						</span>
						<img
							src={exampleVersion}
							alt="Example Version"
							className="w-64 rounded-lg"
						/>
					</div>

					<div className="flex flex-col gap-2">
						<label htmlFor="status" className="w-44">
							<span
								className={`${isDarkMode ? 'text-white' : 'text-black'} font-semibold`}
							>
								{translations[language].status}
							</span>
						</label>
						{user && user.email === 'hypertopiaid@gmail.com' ? (
							<div
								className={`flex gap-2 w-full justify-start ${isDarkMode ? 'text-white' : 'text-black'}`}
							>
								<label className="w-full text-center">
									<input
										type="radio"
										value="Pending"
										checked={state_RequestedVRGames.status === 'Pending'}
										onChange={handleRadioChange}
										className="hidden"
									/>
									<div
										className={`px-4 py-2 rounded-full w-full border ${
											state_RequestedVRGames.status === 'Pending'
												? 'border-2 border-[#0081FB] bg-blue-100'
												: 'border bg-zinc-100'
										} cursor-pointer`}
										onClick={() =>
											handleRadioChange({ target: { value: 'Pending' } })
										}
									>
										{translations[language].pending}
									</div>
								</label>
								<label className="w-full text-center">
									<input
										type="radio"
										value="Process"
										checked={state_RequestedVRGames.status === 'Process'}
										onChange={handleRadioChange}
										className="hidden"
									/>
									<div
										className={`px-4 py-2 rounded-full w-full border ${
											state_RequestedVRGames.status === 'Process'
												? 'border-2 border-[#0081FB] bg-blue-100'
												: 'border bg-zinc-100'
										} cursor-pointer`}
										onClick={() =>
											handleRadioChange({ target: { value: 'Process' } })
										}
									>
										{translations[language].process}
									</div>
								</label>
								<label className="w-full text-center">
									<input
										type="radio"
										value="Done"
										checked={state_RequestedVRGames.status === 'Done'}
										onChange={handleRadioChange}
										className="hidden"
									/>
									<div
										className={`px-4 py-2 rounded-full w-full border ${
											state_RequestedVRGames.status === 'Done'
												? 'border-2 border-[#0081FB] bg-blue-100'
												: 'border bg-zinc-100'
										} cursor-pointer`}
										onClick={() =>
											handleRadioChange({ target: { value: 'Done' } })
										}
									>
										{translations[language].done}
									</div>
								</label>
								<label className="w-full text-center">
									<input
										type="radio"
										value="Canceled"
										checked={state_RequestedVRGames.status === 'Canceled'}
										onChange={handleRadioChange}
										className="hidden"
									/>
									<div
										className={`px-4 py-2 rounded-full w-full border ${
											state_RequestedVRGames.status === 'Canceled'
												? 'border-2 border-[#0081FB] bg-blue-100'
												: 'border bg-zinc-100'
										} cursor-pointer`}
										onClick={() =>
											handleRadioChange({ target: { value: 'Canceled' } })
										}
									>
										{translations[language].canceled}
									</div>
								</label>
							</div>
						) : (
							<input
								type="text"
								id="status"
								name="status"
								placeholder={translations[language].fillHere}
								value={state_RequestedVRGames.status || ''}
								onChange={handleInputChange_RequestedVRGames}
								className="rounded p-2 input-box w-full"
								readOnly
								disabled
							/>
						)}
					</div>
					{user && user.email === 'hypertopiaid@gmail.com' && (
						<div className="flex flex-col gap-2">
							<label htmlFor="report" className="w-44">
								<span
									className={`${isDarkMode ? 'text-white' : 'text-black'} font-semibold`}
								>
									{translations[language].report}
								</span>
							</label>
							<input
								type="text"
								id="report"
								name="report"
								placeholder={translations[language].fillHere}
								value={state_RequestedVRGames.report}
								onChange={handleInputChange_RequestedVRGames}
								className={`rounded p-2 input-box w-full`}
								readOnly
								disabled
							/>
						</div>
					)}
					<div className="flex flex-col gap-2">
						<label htmlFor="reason" className="w-44">
							<span
								className={`${isDarkMode ? 'text-white' : 'text-black'} font-semibold`}
							>
								Alasan
							</span>
						</label>
						{user && user.email === 'hypertopiaid@gmail.com' ? (
							<textarea
								id="reason"
								name="reason"
								placeholder={translations[language].fillHere}
								value={state_RequestedVRGames.reason || ''}
								onChange={handleInputChange_RequestedVRGames}
								className="rounded p-2 input-box w-full"
							/>
						) : (
							<textarea
								id="reason"
								name="reason"
								placeholder={translations[language].fillHere}
								value={state_RequestedVRGames.reason || ''}
								onChange={handleInputChange_RequestedVRGames}
								className="rounded p-2 input-box w-full"
								readOnly
								disabled
							/>
						)}
					</div>

					<div className="flex flex-col gap-2">
						<label htmlFor="timeRequested" className="w-44">
							<span
								className={`${isDarkMode ? 'text-white' : 'text-black'} font-semibold`}
							>
								{translations[language].timeRequested}
							</span>
						</label>
						<input
							type="text"
							id="timeRequested"
							name="timeRequested"
							placeholder={translations[language].fillHere}
							value={formatDate(state_RequestedVRGames.timeRequested)}
							onChange={handleInputChange_RequestedVRGames}
							className={`rounded p-2 input-box w-full`}
							readOnly
							disabled
						/>
					</div>

					{/* Submit Button */}
					<motion.input
						whileHover={{ scale: 1.05 }}
						whileTap={{ scale: 0.95 }}
						type="submit"
						value={
							id ? translations[language].update : translations[language].save
						}
						className="p-3 bg-blue-500 text-white rounded-lg cursor-pointer hover:bg-blue-700 transition-all"
					/>
				</form>

				{/* Meta Quest Store Link */}
				<div className="text-center mt-6 flex flex-col items-center justify-center">
					<span>{translations[language].findGamesInMetaQuestStore}</span>
					<a
						href={`https://www.meta.com/experiences/`}
						target="_blank"
						rel="noopener noreferrer"
					>
						<motion.button
							whileHover={{ scale: 1.05 }}
							whileTap={{ scale: 0.95 }}
							className="bg-[#0081FB] text-white px-4 py-2 rounded-xl mt-4 hover:bg-blue-700 transition-all flex flex-row gap-2 items-center"
						>
							<FaMeta />
							Meta Quest Store
						</motion.button>
					</a>
				</div>
			</motion.div>

			<Helmet>
				<title>
					{id ? `${state_RequestedVRGames.gameTitle}` : 'Add Request VR Games'}{' '}
					| Request VR Games - HyperTopia
				</title>
			</Helmet>

			<style>{`
        .input-box {
          padding: 12px 20px;
          margin: 2px 0;
          display: block;
          border: 1px solid #ccc;
          border-radius: 10px;
          box-sizing: border-box;
        }
      `}</style>
		</div>
	)
}

export default RequestedVRGames
