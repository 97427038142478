import React, { useState, useEffect, useMemo } from 'react'
import { auth, provider } from '../../firebase'
import { useNavigate, Link } from 'react-router-dom'
import logoHypertopia from '../../assets/images/NewLogoOnly.png'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { signInWithEmailAndPassword, signInWithPopup } from 'firebase/auth'
import { FcGoogle } from 'react-icons/fc'
import { useDarkModeContext } from '../../DarkModeContext'
import { Helmet } from 'react-helmet'
import { set } from 'firebase/database'
import fireDb from '../../firebase'

import Particles, { initParticlesEngine } from '@tsparticles/react'
import { loadSlim } from '@tsparticles/slim'

const SignIn = () => {
	const [, setInit] = useState(false)

	useEffect(() => {
		initParticlesEngine(async (engine) => {
			await loadSlim(engine)
		}).then(() => {
			setInit(true)
		})
	}, [])

	const options = {
		fpsLimit: 60,
		background: {
			color: {
				value: '#1E1E1E',
			},
		},
		particles: {
			number: {
				value: 160,
				density: {
					enable: true,
					value_area: 800,
				},
			},
			color: {
				value: '#f7d03d',
			},
			shape: {
				type: 'star',
				stroke: {
					width: 0,
					color: '#000000',
				},
				polygon: {
					nb_sides: 5,
				},
				image: {
					src: 'img/github.svg',
					width: 200,
					height: 200,
				},
			},
			opacity: {
				value: 1,
				random: true,
				anim: {
					enable: true,
					speed: 1,
					opacity_min: 0,
					sync: false,
				},
			},
			size: {
				value: 5,
				random: true,
				anim: {
					enable: false,
					speed: 4,
					size_min: 0.3,
					sync: false,
				},
			},
			line_linked: {
				enable: false,
				distance: 150,
				color: '#ffffff',
				opacity: 0.4,
				width: 1,
			},
			move: {
				enable: true,
				speed: 1,
				direction: 'none',
				random: true,
				straight: false,
				out_mode: 'out',
				bounce: false,
				attract: {
					enable: false,
					rotateX: 600,
					rotateY: 600,
				},
			},
		},
		interactivity: {
			detect_on: 'canvas',
			events: {
				onhover: {
					enable: true,
					mode: 'grab',
				},
				onclick: {
					enable: true,
					mode: 'push',
				},
				resize: true,
			},
			modes: {
				grab: {
					distance: 400,
					line_linked: {
						opacity: 1,
					},
				},
				bubble: {
					distance: 250,
					size: 0,
					duration: 2,
					opacity: 0,
					speed: 3,
				},
				repulse: {
					distance: 400,
					duration: 0.4,
				},
				push: {
					particles_nb: 4,
				},
				remove: {
					particles_nb: 2,
				},
			},
		},
		retina_detect: true,
	}

	const [countdown, setCountdown] = useState(1)
	const [user, setUser] = useState(null)
	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')
	const { isDarkMode, toggleDarkMode } = useDarkModeContext()
	const navigate = useNavigate()

	const storedLanguage = localStorage.getItem('language') || 'id'

	const storedRedirectPath = localStorage.getItem('redirectPath') || '/'

	const allowedEmails = ['hypertopiaid@gmail.com']

	const [language, setLanguage] = useState(storedLanguage)
	const [selectedTheme, setSelectedTheme] = useState('light')
	const [darkMode, setDarkMode] = useState(false)

	useEffect(() => {
		const unsubscribe = auth.onAuthStateChanged((user) => {
			if (user) {
				setUser(user)
			} else {
				setUser(null)
			}
		})

		return () => {
			unsubscribe()
		}
	}, [])

	const signIn = async (e) => {
		e.preventDefault()

		try {
			await signInWithEmailAndPassword(auth, email, password)

			// Menambahkan timestamp dan status online ke database
			const userRef = fireDb.ref(`/usersLogin/${user.displayName}`)
			set(userRef, { lastLogin: new Date().toISOString() })

			// Update state pengguna setelah login berhasil
			setUser(auth.currentUser)
		} catch (error) {
			// Tangani kesalahan
			console.error(error.message)
		}
	}

	const handleGoogleSignIn = () => {
		signInWithPopup(auth, provider)
			.then((result) => {
				const user = result.user
				if (user.disabled) {
					setUser(null)
				} else {
					setUser(user)
					localStorage.setItem('user', JSON.stringify(user))
				}
			})
			.catch((error) => {})
	}

	useEffect(() => {
		let countdownInterval
		if (user) {
			let secondsLeft = countdown
			const countdownInterval = setInterval(() => {
				secondsLeft -= 1
				setCountdown(secondsLeft)

				if (secondsLeft === -1) {
					clearInterval(countdownInterval)

					if (user && !allowedEmails.includes(user.email)) {
						// Jika user terautentikasi tapi email-nya tidak diizinkan, kembalikan ke halaman utama
						navigate('/dashboard')
					} else {
						navigate('/dba/dashboard')
					}
				}
			}, 1000) // Update countdown every second
		}

		// Clear interval when the component unmounts
		return () => {
			clearInterval(countdownInterval)
		}
	}, [countdown, navigate, storedRedirectPath, user])

	const handleLogout = () => {
		setUser(null)
	}

	const handleLanguageChange = (event) => {
		const selectedLanguage = event.target.value
		setLanguage(selectedLanguage)
		localStorage.setItem('language', selectedLanguage)
	}

	useEffect(() => {
		localStorage.setItem('language', language)
	}, [language])

	const translations = {
		en: {
			loginWith: 'Login with',
			loginText: 'Log In to your Account',
			login: 'Login',
			enterEmail: 'Enter your email',
			enterPassword: 'Enter your password',
			or: 'or',

			welcome: 'Welcome',
			redirecting: 'Redirecting to ',
			home: 'Home',
			profile: 'Profile',
			dashboardAdmin: 'Dashboard Admin',
			dashboard: 'Dashboard',
			in: ' in',
			s: 's',

			dark: 'Dark',
			light: 'Light',

			signIn: 'Sign In',

			infoSignIn:
				'By closing any pages related to HYPERTOPIA, you will automatically log out of your account for security reasons!',

			givenEmail:
				'If you have already purchased a product, please log in with the Google email that has been included/given to us when ordering!',
		},
		id: {
			loginWith: 'Masuk dengan',
			loginText: 'Masuk ke Akun Anda',
			login: 'Masuk',
			enterEmail: 'Masukkan email Anda',
			enterPassword: 'Masukkan kata sandi Anda',
			or: 'atau',

			welcome: 'Selamat Datang',
			redirecting: 'Mengalihkan ke ',
			home: 'Beranda',
			profile: 'Profil',
			dashboardAdmin: 'Dasbor Admin',
			dashboard: 'Dasbor',
			in: ' dalam',
			s: ' detik',

			dark: 'Gelap',
			light: 'Terang',

			signIn: 'Masuk',

			infoSignIn:
				'Dengan Anda menutup laman apapun yang berkaitan dengan laman HYPERTOPIA, maka secara otomatis keluar dari akun Anda demi keamanan!',

			givenEmail:
				'Apabila sudah beli produk, silahkan login dengan email Google yang sudah dicantumkan/diberikan kepada kami pada saat memesan!',
		},
	}

	return (
		<div className="flex items-center justify-center h-svh w-full">
			<Particles id="tsparticles" options={options} />
			<div className="flex items-center justify-end absolute top-4 right-4 w-full z-10">
				{/* Tambahkan elemen bahasa di pojok kanan atas */}
				<div className="flex px-2 gap-6 flex-row items-center justify-between">
					<select
						onChange={handleLanguageChange}
						value={language}
						className={`${isDarkMode ? 'bg-zinc-700 hover:bg-zinc-500 text-white' : 'bg-white hover:bg-zinc-200 text-black'} outline-none px-2 py-2 w-full cursor-pointer ${isDarkMode ? 'border-zinc-500' : 'border-zinc-300'} border-2 rounded-xl`}
						role="menuitem"
					>
						<option value="en">EN</option>
						<option value="id">ID</option>
					</select>
				</div>
			</div>

			<div className="flex flex-col items-center gap-4 max-w-96">
				{user ? (
					<>
						<div
							className={`p-8 border-2 shadow-lg w-full rounded-2xl flex flex-col items-center justify-center gap-3 backdrop-blur-sm bg-zinc-700 bg-opacity-50 text-white`}
						>
							<h3 className="text-center">
								{translations[language].welcome} <br />{' '}
								<span className="font-bold">{user.displayName}</span>
							</h3>
							<div className="">
								<img
									src={user.photoURL}
									alt="user"
									referrerPolicy="no-referrer"
									className="rounded-full border-4"
								/>
							</div>
							<p>{user.email}</p>
						</div>
						{/* {countdown > 0 && (
              <p className='text-white px-4 py-2 bg-[#0081FB] rounded-lg w-full text-center'>{translations[language].redirecting} <span className='font-bold animate-pulse'>{user && !allowedEmails.includes(user.email) ? translations[language].home : translations[language].dashboardAdmin}</span> {translations[language].in} <span className='font-bold'>{countdown}</span>{translations[language].s}</p>
            )} */}
					</>
				) : (
					<>
						<div
							className={`p-8 border-2 rounded-2xl w-[375px] flex flex-col items-center shadow-lg backdrop-blur-sm bg-zinc-700 bg-opacity-50`}
						>
							<Link to="/">
								<LazyLoadImage
									src={logoHypertopia}
									alt="hypertopia-logo"
									className="mb-5"
									width={70}
								/>
							</Link>
							<h1 className={`mb-3 font-bold text-white`}>
								{translations[language].loginText}
							</h1>
							<h1 className={`mb-4 text-sm text-center text-white`}>
								{translations[language].givenEmail}
							</h1>
							<button
								className="hover:bg-gray-300 transition-all h-full duration-300 ease-in-out w-full bg-white border-2 py-3 rounded-xl flex flex-row gap-2 items-center justify-center"
								onClick={handleGoogleSignIn}
							>
								<FcGoogle className="w-6 h-6" />
								<p className="font-semibold">
									{translations[language].loginWith} Google
								</p>
							</button>
						</div>
					</>
				)}
			</div>

			<Helmet>
				<title>{translations[language].signIn} | Hypertopia</title>
			</Helmet>

			<style>
				{`
        @keyframes glow {
          0% {
            box-shadow: 0 0 5px 0 ${isDarkMode ? 'rgba(255, 255, 255, 0.2)' : 'rgba(212, 212, 216, 0.2)'};
          }
          50% {
            box-shadow: 0 0 80px 5px ${isDarkMode ? 'rgba(255, 255, 255, 0.6)' : 'rgba(212, 212, 216, 0.6)'};
          }
          100% {
            box-shadow: 0 0 5px 0 ${isDarkMode ? 'rgba(255, 255, 255, 0.2)' : 'rgba(212, 212, 216, 0.2)'};
          }
        }

        .glow-effect {
          animation: glow 6s infinite;
        }

        .input-box {
          width: 300px;
          padding: 12px 20px;
          margin: 2px 0;
          display: block;
          border: 1px solid #ccc;
          border-radius: 10px;
          box-sizing: border-box;
        }
        `}
			</style>
		</div>
	)
}

export default SignIn