import React, { useEffect, useState } from 'react'
import { FaDownload } from 'react-icons/fa6'
import TutorialBeatSaber from './TutorialQuestPatcher'
import bgPage from '../../assets/images/bg.jpg'
import { Helmet } from 'react-helmet'
import { FaTools } from 'react-icons/fa'

const BeatSaberMod = () => {
	const [mods, setMods] = useState([])
	const [selectedVersionGroup, setSelectedVersionGroup] = useState('')
	const [versions, setVersions] = useState([])
	const [searchTerm, setSearchTerm] = useState('')

	useEffect(() => {
		const fetchVersions = async () => {
			try {
				const response = await fetch('https://mods.bsquest.xyz/versions.json')
				const data = await response.json()

				const filteredVersions = data.filter(
					(version) => version !== 'undefined' && version !== 'global'
				)

				const sortedVersions = filteredVersions.sort((a, b) =>
					b.localeCompare(a)
				)

				setVersions(sortedVersions)

				// Langsung set ke versi tertentu jika ada
				const defaultVersion = sortedVersions.find(
					(version) => version === '1.37.0_9064817954'
				)
				setSelectedVersionGroup(defaultVersion || sortedVersions[0]) // Gunakan versi default atau fallback ke versi pertama
			} catch (error) {
				console.error('Error fetching versions:', error)
			}
		}

		fetchVersions()
	}, [])

	useEffect(() => {
		if (!selectedVersionGroup) return

		const fetchData = async () => {
			try {
				const modData = await fetch(
					`https://mods.bsquest.xyz/${selectedVersionGroup}.json`
				)
					.then((response) => response.json())
					.catch((err) => console.error('Error fetching mod data', err))

				const combinedMods = Object.entries(modData).map(
					([modId, modVersions]) => ({
						modId,
						versions: Object.entries(modVersions).map(([version, mod]) => ({
							...mod,
							version,
						})),
					})
				)

				combinedMods.forEach((mod) => {
					mod.versions.sort((a, b) => b.version.localeCompare(a.version))
				})

				setMods(combinedMods)
			} catch (error) {
				console.error('Error fetching mods:', error)
			}
		}

		fetchData()
	}, [selectedVersionGroup])

	const handleVersionGroupChange = (versionGroup) => {
		setSelectedVersionGroup(versionGroup)
	}

	const handleModVersionChange = (modId, version) => {
		const updatedMods = mods.map((mod) => {
			if (mod.modId === modId) {
				const selectedModVersion = mod.versions.find(
					(v) => v.version === version
				)
				return {
					...mod,
					selectedVersion: selectedModVersion,
				}
			}
			return mod
		})
		setMods(updatedMods)
	}

	const filteredMods = mods.filter((mod) =>
		mod.modId.toLowerCase().includes(searchTerm.toLowerCase())
	)

	const [isMobile, setIsMobile] = useState(window.innerWidth < 1200)

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth < 1200)
		}

		window.addEventListener('resize', handleResize)

		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])

	return (
		<div className={`min-h-screen bg-white text-gray-900 w-full`}>
			{/* Header Baru */}
			<header
				className="bg-gradient-to-r from-[#0881FB] to-[#0059b2] py-12 text-center text-white px-6 sm:px-10 pt-[120px]"
				style={{
					background: `linear-gradient(to left, rgba(8, 129, 251, 0.95) 10%, rgba(0, 89, 178, 0.95) 100%), url(${bgPage}) center/cover`,
				}}
			>
				<div className="flex items-center justify-center gap-4 text-3xl md:text-4xl ">
					<FaTools />
					<h1 className="font-bold tracking-wide">Beat Saber Mods</h1>
				</div>
				<p className="text-lg md:text-xl mt-4">
					Jelajahi dan unduh mod untuk pengalaman Beat Saber kamu!
				</p>
				<p className="w-full flex items-center justify-center mt-4">
					<a
						href="https://mods.bsquest.xyz/"
						className="px-4 py-2.5 flex items-center justify-center rounded-full w-fit bg-white text-[#0081FB] font-semibold text-sm md:text-base"
						target="_blank"
						rel="noopener noreferrer"
					>
						BSQMods • Dibuat oleh DanTheMan827
					</a>
				</p>
			</header>

			{/* Sticky Search Bar dan Select Version Group */}
			<div className="sticky top-[70px] bg-white shadow-lg z-20 py-6 px-6 space-y-4">
				<div className="flex gap-6 items-center justify-between">
					{/* Search Bar */}
					<div className="w-2/3">
						<input
							type="text"
							placeholder="Cari mod..."
							className="w-full p-4 rounded-xl shadow-lg border-2 border-[#0081FB] focus:outline-none transition duration-300 ease-in-out"
							value={searchTerm}
							onChange={(e) => setSearchTerm(e.target.value)}
						/>
					</div>

					{/* Select Version Group */}
					<div className="w-1/3">
						<select
							className="w-full p-4 rounded-xl shadow-lg bg-white text-gray-900 border-2 border-[#0881FB] focus:outline-none transition duration-300 ease-in-out"
							value={selectedVersionGroup}
							onChange={(e) => handleVersionGroupChange(e.target.value)}
						>
							{versions.map((version) => (
								<option key={version} value={version}>
									{version}
								</option>
							))}
						</select>
					</div>
				</div>
			</div>

			{/* Tutorial Section */}
			<TutorialBeatSaber />

			<div className={`flex flex-wrap gap-6 justify-center pb-10`}>
				{/* Mod Preview */}
				{filteredMods.map((mod) => (
					<div
						key={mod.modId}
						className="w-80 bg-white rounded-2xl shadow-xl overflow-hidden flex flex-col"
					>
						<img
							src={
								mod.versions[0].cover || 'https://via.placeholder.com/300x180'
							}
							alt={mod.modId}
							className="w-full h-48 object-cover rounded-xl"
						/>
						<div className="p-4 flex flex-col flex-grow">
							<h3 className="text-xl font-semibold text-gray-900">
								{mod.modId}
							</h3>
							<p className="text-sm text-gray-700 mt-2">
								{mod.versions[0].description}
							</p>

							<div className="flex items-center mt-2">
								{mod.versions[0].authorIcon && (
									<img
										src={mod.versions[0].authorIcon}
										alt={mod.versions[0].author}
										className="w-6 h-6 rounded-full mr-2"
									/>
								)}
								<span className="text-sm text-gray-700">
									{mod.versions[0].author}
								</span>
							</div>

							{/* <div className="flex justify-start my-4 space-x-4">
								{mod.versions[0].funding && (
									<a
										href={mod.versions[0].funding}
										target="_blank"
										rel="noopener noreferrer"
										title="Dukung mod ini"
									>
										<i className="fas fa-heart text-red-600" />
									</a>
								)}

								<a
									href={mod.versions[0].website}
									target="_blank"
									rel="noopener noreferrer"
									title="Kunjungi Website"
								>
									<FaGlobe className="text-blue-500" />
								</a>

								{mod.versions[0].source && (
									<a
										href={mod.versions[0].source}
										target="_blank"
										rel="noopener noreferrer"
										title="Lihat Sumber"
									>
										<i className="fas fa-code text-green-500" />
									</a>
								)}
							</div> */}

							<div className="h-8" />

							{/* Dropdown select dan tombol download di bagian bawah */}
							<div className="flex flex-row items-center justify-center gap-2 w-full mt-auto">
								<select
									className="w-full p-2 bg-gray-200 text-gray-900 rounded-md h-10"
									value={
										mod.selectedVersion
											? mod.selectedVersion.version
											: mod.versions[0].version
									}
									onChange={(e) =>
										handleModVersionChange(mod.modId, e.target.value)
									}
								>
									{mod.versions.map((versionOption) => (
										<option
											key={versionOption.version}
											value={versionOption.version}
										>
											{versionOption.version}
										</option>
									))}
								</select>

								<a
									href={
										mod.selectedVersion
											? mod.selectedVersion.download
											: mod.versions[0].download
									}
									className="h-10 px-4 bg-[#0881FB] text-white rounded-md text-center hover:bg-[#0059b2] flex justify-center items-center"
								>
									<FaDownload />
								</a>
							</div>
						</div>
					</div>
				))}
			</div>

			<Helmet>
				<title>Beat Saber Mods | HyperTopia</title>
			</Helmet>
		</div>
	)
}

export default BeatSaberMod
