import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { FaCartShopping, FaUser } from 'react-icons/fa6'
import { Link } from 'react-router-dom'
import { MdRedeem } from 'react-icons/md'
import { motion } from 'framer-motion'
import bgPage from '../../assets/images/bg.jpg'
import { BsReceipt } from 'react-icons/bs'

const Account = () => {
	const language = localStorage.getItem('language') || 'id'

	const translations = {
		en: {
			account: 'Account',
			hintInfo: 'Choose one of the menus below to access the desired page!',
			profile: 'Profile',
			redeem: 'Redeem',
			cart: 'Cart',
			orders: 'Orders',
			profileDescInfo: 'View and edit your profile information',
			redeemDescInfo: 'Get access to download games based on your order number',
			cartDescInfo: 'View and manage items you have added',
			ordersDescInfo: 'View your order status',
		},
		id: {
			account: 'Akun',
			hintInfo: 'Pilih salah satu menu di bawah untuk mengakses halaman!',
			profile: 'Profil',
			redeem: 'Penukaran',
			cart: 'Keranjang',
			orders: 'Pesanan',
			profileDescInfo: 'Lihat dan ubah informasi profil Anda',
			redeemDescInfo: 'Dapatkan akses download game berdasarkan nomor pesanan Anda',
			cartDescInfo: 'Lihat dan kelola barang yang telah Anda tambahkan',
			ordersDescInfo: 'Lihat status pesanan Anda',
		},
	}

	const [gridColsVRGamesMenu, setGridColsVRGamesMenu] = useState('grid-cols-1')

	useEffect(() => {
		const handleResize = () => {
			setGridColsVRGamesMenu(
				window.innerWidth >= 800 ? 'grid-cols-2' : 'grid-cols-1'
			)
		}

		handleResize()
		window.addEventListener('resize', handleResize)

		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])

	const [isMobile, setIsMobile] = useState(window.innerWidth < 1200)

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth < 1200)
		}

		window.addEventListener('resize', handleResize)

		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])

	const menuItems = [
		{
			icon: <FaUser size={50} />,
			label: translations[language].profile,
			description: translations[language].profileDescInfo,
			link: './profile',
		},
		{
			icon: <MdRedeem size={50} />,
			label: translations[language].redeem,
			description: translations[language].redeemDescInfo,
			link: './profile',
		},
		{
			icon: <FaCartShopping size={50} />,
			label: translations[language].cart,
			description: translations[language].cartDescInfo,
			link: './profile',
		},
		{
			icon: <BsReceipt size={50} />,
			label: translations[language].orders,
			description: translations[language].ordersDescInfo,
			link: './profile',
		},
	]

	return (
		<div className={`w-full min-h-screen`}>
			<Helmet>
				<title>{translations[language].account} | HyperTopia</title>
			</Helmet>

			<header
				className="bg-gradient-to-r from-[#0881FB] to-[#0059b2] py-12 text-center text-white px-6 sm:px-10 pt-[120px]"
				style={{
					background: `linear-gradient(to left, rgba(8, 129, 251, 0.95) 10%, rgba(0, 89, 178, 0.95) 100%), url(${bgPage}) center/cover`,
				}}
			>
				<h1 className="text-3xl md:text-4xl font-bold tracking-wide">
					{translations[language].account}
				</h1>
				<p className="text-base md:text-lg mt-4">
					{translations[language].hintInfo}
				</p>
			</header>

			<div className={`pt-7 mx-7 pb-7 grid ${gridColsVRGamesMenu} gap-7`}>
				{menuItems.map((item, index) => (
					<Link to={item.link} key={index}>
						<motion.div
							whileHover={{ scale: 1.02 }}
							whileTap={{ scale: 0.95 }}
							className="hover:bg-gradient-to-br from-blue-400 to-blue-600 bg-white text-gray-700 hover:text-white transition-all rounded-3xl border-2 p-6 flex gap-4 items-center shadow-md"
						>
							<div className="flex gap-4 items-center">
								<div className="text-3xl">{item.icon}</div>
								<div>
									<h3 className="text-xl font-semibold">{item.label}</h3>
									<p className="text-sm">{item.description}</p>
								</div>
							</div>
						</motion.div>
					</Link>
				))}
			</div>
		</div>
	)
}

export default Account
