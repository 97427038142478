import React, { useState, useEffect } from 'react'
import {
	BrowserRouter as Router,
	Route,
	Routes,
	useLocation,
} from 'react-router-dom'
import Home from './pages/Home'
import { DarkModeProvider } from './DarkModeContext'
import SignIn from './pages/Account/SignIn'
import Products from './pages/Products/Products'
import fireDb from './firebase'

import { auth } from './firebase'

import { FaBoxes, FaHome, FaTools } from 'react-icons/fa'

import Account from './pages/Account/Account'
import Profile from './pages/Account/Profile'
import RequestVRGames from './pages/VR Games/RequestVRGames'
import Cart from './pages/Account/Cart'
import ErrorPage from './pages/ErrorPage'
import VRGames from './pages/VR Games/VRGames'
import ListGames from './pages/VR Games/ListGames'

import StandaloneListGames from './pages/VR Games/List Games/Standalone/ListGames_Standalone'

import Tutorials from './pages/VR Games/Tutorials'
import RequestGames from './pages/VR Games/RequestGames'
import Orders from './pages/Account/Orders'
import Payment from './pages/Account/Payment'
import SoftwareHelper from './pages/VR Games/SoftwareHelper'
import { BsBoxSeamFill, BsHeadsetVr, BsReceipt } from 'react-icons/bs'
import {
	FaAddressCard,
	FaBook,
	FaCartShopping,
	FaGamepad,
	FaMessage,
	FaRankingStar,
	FaUser,
} from 'react-icons/fa6'
import {
	MdDashboard,
	MdGroups,
	MdOutlineSystemUpdateAlt,
	MdRedeem,
} from 'react-icons/md'
import Dashboard from './pages/Dashboard'
import LeaderboardGames from './pages/VR Games/LeaderboardGames'

import { useNetworkState } from 'react-use'
import toast, { Toaster } from 'react-hot-toast'
import Settings from './pages/Settings/Settings'
import PCVRListGames from './pages/VR Games/List Games/PCVR/ListGames_PCVR'
import MultiplayerStatus from './pages/VR Games/MultiplayerStatus'
import NintendoSwitch from './pages/Nintendo Switch/NintendoSwitch'
import SidebarMobile, { SidebarMobileItem } from './pages/SidebarMobile'
import NavBar from './pages/NavBar'
import FirmwareQuest from './pages/VR Games/FirmwareQuest'
import BeatSaberMod from './pages/VR Games/BeatSaberMod'
import ModDownloader from './pages/VR Games/ModDownloader'
import RedeemPage from './pages/Account/RedeemPage'
import GoogleDriveFile from './pages/Testing'

const App = () => {
	return (
		<DarkModeProvider>
			<Router>
				<Routes>
					<Route path="/*" element={<AppLayout />} />
				</Routes>
			</Router>
		</DarkModeProvider>
	)
}

const AppLayout = () => {
	const location = useLocation()

	const [user, setUser] = useState(null)

	const [isDisconnected, setIsDisconnected] = useState(false)
	const networkState = useNetworkState()

	const [isMobile, setIsMobile] = useState(window.innerWidth < 1200)

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth < 1200)
		}

		window.addEventListener('resize', handleResize)

		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])

	useEffect(() => {
		setIsDisconnected(!networkState.online)
	}, [networkState.online])

	// Memeriksa perubahan status koneksi
	useEffect(() => {
		// Jika terputus dari internet dan sebelumnya terhubung, tampilkan toast
		if (!networkState.online && !isDisconnected) {
			toast.error('Koneksi internet bermasalah, harap periksa koneksi Anda.')
			setIsDisconnected(true) // Set isDisconnected ke true untuk menghindari spamming
		}

		// Jika terhubung kembali, set isDisconnected ke false
		if (networkState.online && isDisconnected) {
			toast.success('Koneksi internet telah terhubung kembali.')
			setIsDisconnected(false)
		}
	}, [networkState.online, isDisconnected])

	const translations = {
		en: {
			home: 'Home',
			dashboard: 'Dashboard',

			listGames: 'List Games',
			products: 'Products',
			purchase: 'Purchase',
			account: 'Account',
			profile: 'Profile',
			settings: 'Settings',

			yourOrders: 'Orders',
			cart: 'Cart',
			redeem: 'Redeem',

			listVRGames: 'VR Games',
			softwareHelper: 'Software Helper',
			tutorials: 'Tutorials',
			requestGames: 'Request Games',
			leaderboard: 'Leaderboard',
			multiplayerStatus: 'Multiplayer Status',

			chat: 'Chat with Admin',

			saveData: 'Data Saver',

			language: 'Language',
			theme: 'Theme',
			dark: 'Dark',
			light: 'Light',

			signIn: 'Sign In',
			signOut: 'Sign Out',
			adminDashboard: 'Admin Dashboard',

			tooltipDataSaver: 'Game cover images will not be displayed to save data.',
		},
		id: {
			home: 'Beranda',
			dashboard: 'Dasbor',

			listGames: 'Daftar Game',
			products: 'Produk',
			purchase: 'Beli',
			account: 'Akun',
			profile: 'Profil',
			settings: 'Pengaturan',

			yourOrders: 'Pesanan',
			cart: 'Keranjang',
			redeem: 'Penukaran',

			listVRGames: 'Game VR',
			softwareHelper: 'Software Pembantu',
			tutorials: 'Tutorial',
			requestGames: 'Request Game',
			leaderboard: 'Papan Peringkat',
			multiplayerStatus: 'Status Multiplayer',

			chat: 'Chat dengan Admin',

			saveData: 'Hemat Data',

			language: 'Bahasa',
			theme: 'Tema',
			dark: 'Gelap',
			light: 'Terang',

			signIn: 'Masuk',
			signOut: 'Keluar',
			adminDashboard: 'Dasbor Admin',

			tooltipDataSaver:
				'Cover gambar setiap game tidak akan ditampilkan untuk menghemat data.',
		},
	}

	const language = localStorage.getItem('language') || 'id'
	const [cartItems, setCartItems] = useState([])
	const [orders, setOrders] = useState([])

	useEffect(() => {
		if (user) {
			const cartRef = fireDb.ref(`cart/${user.uid}`)

			// Listen for changes in the cart
			const handleSnapshot = (snapshot) => {
				const items = snapshot.val()
				const cartItemList = items ? Object.values(items) : []
				setCartItems(cartItemList)
			}

			cartRef.on('value', handleSnapshot)

			return () => {
				// Cleanup the event listener when the component unmounts
				cartRef.off('value', handleSnapshot)
			}
		}
	}, [user])

	useEffect(() => {
		if (user) {
			const ordersRef = fireDb.ref(`orders/${user.uid}`)

			// Listen for changes in the orders
			const handleSnapshot = (snapshot) => {
				const ordersData = snapshot.val()
				const ordersList = ordersData
					? Object.entries(ordersData).map(([orderId, orderData]) => ({
							...orderData,
							orderId,
						}))
					: []
				setOrders(ordersList)
			}

			ordersRef.on('value', handleSnapshot)

			return () => {
				// Cleanup the event listener when the component unmounts
				ordersRef.off('value', handleSnapshot)
			}
		}
	}, [user])

	useEffect(() => {
		if (location.pathname !== '/signin') {
			localStorage.setItem('redirectPath', location.pathname)
		}
	}, [location.pathname])

	useEffect(() => {
		if (!localStorage.getItem('dataSaver')) {
			localStorage.setItem('dataSaver', true)
		}
	}, [])

	useEffect(() => {
		const unsubscribe = auth.onAuthStateChanged((user) => {
			if (user) {
				setUser(user)
			} else {
				setUser(null)
			}
		})

		return () => {
			unsubscribe()
		}
	}, [])

	const dropdownItems_VRGames = [
		{
			text: translations[language].listGames,
			link: '/vr-games/list-games',
			icon: <FaGamepad />,
			active: location.pathname.startsWith('/vr-games/list-games')
				? true
				: false,
		},
		{
			text: translations[language].softwareHelper,
			link: '/vr-games/software-helper',
			icon: <BsBoxSeamFill />,
			active: location.pathname === '/vr-games/software-helper' ? true : false,
		},
		{
			text: translations[language].tutorials,
			link: '/vr-games/tutorials',
			icon: <FaBook />,
			active: location.pathname === '/vr-games/tutorials' ? true : false,
		},
		{
			text: translations[language].requestGames,
			link: '/vr-games/request-games',
			icon: <FaMessage />,
			active: location.pathname === '/vr-games/request-games' ? true : false,
		},
		{
			text: 'Beat Saber Mod',
			link: '/vr-games/beat-saber-mod',
			icon: <FaTools />,
			active: location.pathname === '/vr-games/beat-saber-mod' ? true : false,
		},
		{
			text: 'Firmware Quest',
			link: '/vr-games/firmware-quest',
			icon: <MdOutlineSystemUpdateAlt />,
			active: location.pathname === '/vr-games/firmware-quest' ? true : false,
		},
		{
			text: translations[language].leaderboard,
			link: '/vr-games/leaderboard',
			icon: <FaRankingStar />,
			active: location.pathname === '/vr-games/leaderboard' ? true : false,
		},
		{
			text: translations[language].multiplayerStatus,
			link: '/vr-games/multiplayer-status',
			icon: <MdGroups />,
			active:
				location.pathname === '/vr-games/multiplayer-status' ? true : false,
		},
	]

	const dropdownItems_Account = [
		{
			text: translations[language].profile,
			link: '/account/profile',
			icon: <FaAddressCard />,
			active: location.pathname === '/account/profile' ? true : false,
		},
		{
			text: translations[language].redeem,
			link: '/account/redeem',
			icon: <MdRedeem />,
			active: location.pathname === '/account/redeem' ? true : false,
		},
		{
			text: translations[language].cart,
			link: '/account/cart',
			icon: <FaCartShopping />,
			active: location.pathname === '/account/cart' ? true : false,
			count: cartItems.length,
			user: user,
		},
		{
			text: translations[language].yourOrders,
			link: '/account/orders',
			icon: <BsReceipt />,
			active: location.pathname === '/account/orders' ? true : false,
			count: orders.length,
			user: user,
		},
	]

	return (
		<div
			className={`min-h-screen bg-repeat bg-white flex flex-row`}
			// style={{
			// 	background: !location.pathname.startsWith('/signin')
			// 		? `linear-gradient(to left, rgba(255, 255, 255, 0.95) 10%, rgba(255, 255, 255, 0.95) 100%, rgba(255, 255, 255, 0.95) 100%), url(${bgPage}) center/cover`
			// 		: undefined,
			// }}
		>
			<Toaster />

			{!location.pathname.startsWith('/signin') &&
				(isMobile ? (
					<SidebarMobile>
						<>
							{user ? (
								<SidebarMobileItem
									link="/dashboard"
									icon={<MdDashboard />}
									text={translations[language].dashboard}
									active={location.pathname === '/dashboard' ? true : false}
								/>
							) : (
								<SidebarMobileItem
									link="/"
									icon={<FaHome />}
									text={translations[language].home}
									active={location.pathname === '/' ? true : false}
								/>
							)}
							<SidebarMobileItem
								link="/products"
								icon={<FaBoxes />}
								text={translations[language].products}
								active={location.pathname === '/products' ? true : false}
							/>
							<SidebarMobileItem
								link="/vr-games"
								icon={<BsHeadsetVr />}
								text={translations[language].listVRGames}
								active={
									location.pathname.startsWith('/vr-games') ? true : false
								}
								dropdownItems_VRGames={dropdownItems_VRGames}
							/>
							{/* <SidebarMobileItem link='/nintendo-switch' icon={<BsNintendoSwitch />} text="Nintendo Switch" active={location.pathname.startsWith('/nintendo-switch') ? true : false} dropdownItems_NintendoSwitch={dropdownItems_NintendoSwitch} /> */}
							<SidebarMobileItem
								link="/account/profile"
								icon={<FaUser />}
								text={translations[language].account}
								active={location.pathname.startsWith('/account') ? true : false}
								dropdownItems_Account={dropdownItems_Account}
							/>
							{/* <SidebarMobileItem link='/settings' icon={<FaGear />} text={translations[language].settings} active={location.pathname.startsWith('/settings') ? true : false} /> */}
						</>
					</SidebarMobile>
				) : (
					<NavBar />
				))}
			<Routes>
				<Route path="/" element={<Home />} />
				<Route path="/dashboard" element={<Dashboard />} />
				<Route path="/signin" element={<SignIn />} />

				{/* USERS */}
				<Route path="/vr-games" element={<VRGames />} />
				<Route path="/vr-games/beat-saber-mod" element={<BeatSaberMod />} />
				{/* <Route path="/vr-games/firmware-quest" element={<FirmwareQuest />} /> */}
				<Route path="/vr-games/software-helper" element={<SoftwareHelper />} />
				<Route path="/vr-games/list-games" element={<ListGames />} />
				<Route
					path="/vr-games/list-games/standalone"
					element={<StandaloneListGames />}
				/>
				<Route path="/vr-games/list-games/pcvr" element={<PCVRListGames />} />
				<Route path="/vr-games/tutorials" element={<Tutorials />} />
				<Route path="/vr-games/request-games" element={<RequestGames />} />
				<Route
					path="/vr-games/request-games/add"
					element={<RequestVRGames />}
				/>
				<Route
					path="/vr-games/request-games/update/:id"
					element={<RequestVRGames />}
				/>
				<Route path="/vr-games/leaderboard" element={<LeaderboardGames />} />
				<Route
					path="/vr-games/multiplayer-status"
					element={<MultiplayerStatus />}
				/>

				<Route path="/nintendo-switch" element={<NintendoSwitch />} />

				<Route path="/products" element={<Products />} />

				<Route path="/account" element={<Account />} />
				<Route path="/account/profile" element={<Profile />} />
				<Route path="/account/cart" element={<Cart />} />
				<Route path="/account/orders" element={<Orders />} />
				<Route path="/account/orders/:orderId" element={<Payment />} />

				<Route path="/account/redeem" element={<RedeemPage />} />

				<Route path="/settings" element={<Settings />} />

				<Route path="/testing" element={<ModDownloader />} />

				<Route path="/test" element={<GoogleDriveFile />} />

				<Route path="*" element={<ErrorPage />} />
			</Routes>

			<style>
				{`
        /* Style untuk scrollbar */
        ::-webkit-scrollbar {
          width: 8px; /* Lebar scrollbar */
        }

        /* Track scrollbar */
        ::-webkit-scrollbar-track {
          border-radius: 10px; /* Border radius untuk track */
        }

        /* Handle scrollbar */
        ::-webkit-scrollbar-thumb {
          background-color: #888; /* Warna handle */
          border-radius: 10px; /* Border radius untuk handle */
        }

        /* Ketika hover pada handle */
        ::-webkit-scrollbar-thumb:hover {
          background-color: #555; /* Warna handle saat hover */
        }
        `}
			</style>
		</div>
	)
}

export default App
