import React from 'react'
import { FaBoxes } from 'react-icons/fa'
import ProductsCardHome from '../Products/ProductsCard_Home'
import { motion } from 'framer-motion'

const ProductsSection = ({ products, translations, language }) => (
	<section className="py-16 bg-gradient-to-br from-blue-50 to-blue-100 relative overflow-hidden px-4 md:px-6">
		{/* Decorative Circles */}
		<div className="absolute -top-10 -left-10 w-24 h-24 md:w-32 md:h-32 bg-[#0081FB] opacity-20 rounded-full"></div>
		<div className="absolute bottom-0 right-0 w-28 h-28 md:w-40 md:h-40 bg-[#0081FB] opacity-20 rounded-full"></div>

		{/* Title Section with Icon */}
		<div className="flex flex-col items-center mb-8 md:mb-12">
			<div className="flex items-center justify-center gap-2 md:gap-3 mb-2">
				<FaBoxes className="text-3xl md:text-4xl text-[#0081FB]" />
				<h1 className="text-3xl md:text-4xl font-bold text-[#0081FB]">
					{translations[language].products}
				</h1>
			</div>
			<p className="text-gray-600 text-base md:text-lg max-w-xl text-center">
				{translations[language].productsSubtitle}
			</p>
		</div>

		{/* Products Grid with Animation */}
		<div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 max-w-screen-2xl mx-auto">
			{products.map((product, index) => (
				<motion.div
					key={index}
					initial={{ opacity: 0, y: 20 }}
					animate={{ opacity: 1, y: 0 }}
					transition={{ duration: 0.5, delay: index * 0.1 }}
					className="w-full h-full"
				>
					<ProductsCardHome product={product} language={language} />
				</motion.div>
			))}
		</div>
	</section>
)

export default ProductsSection
